import React, { useState } from 'react';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Modal } from '../Modal/Modal';
import { formatDate } from '../../models/Date/Date';
import { Icon } from '../Icon/Icon';
import { Measure } from '../../models/Measure/Types';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useAppDispatch } from '../../hooks';
import { participantRemovedFromMeasure } from '../../models/Participant/Slice';

interface SingleParticipantInformationProps {
    participant: ParticipantWithPresence;

    // In some cases the current measure is needed, in this case the measure should be passed
    measure?: Measure;

    // Shows the icon to remove a participant from the measure
    isRemoveIconEnabled?: boolean;
}

export const SingleParticipantInformation = (props: SingleParticipantInformationProps) => {
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const { apiRemoveParticipantFromMeasure } = useMeasuresApi();
    const dispatch = useAppDispatch();

    /**
     * Called if the user wants to remove the participant from the current measure.
     * Shows a confirmation to the user. if the user confirms, the participant will be removed from the measure.
     */
    const onClickRemove = () => {
        setShowRemoveModal(true);
    };

    /**
     * Called if the user confirms the removal of a participant from the measure.
     * Removes the participant from the measure.
     */
    const onConfirmRemove = async () => {
        if (!props.measure) return;

        try {
            await apiRemoveParticipantFromMeasure(props.measure.id, props.participant.id);

            dispatch(participantRemovedFromMeasure(props.participant));

            showSuccessMessage();
        } catch (e) {
            throwError();
            console.log(e);
        }

        setShowRemoveModal(false);
    };

    /**
     * Called if the user cancels the removal of a participant from the measure.
     * Hides the confirmation modal.
     */
    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    return (
        <div className="participants-management-table entry">
            <div className="p4-medium">
                {props.participant.lastName}, {props.participant.firstName}{' '}
            </div>
            <div className="p4-medium">{formatDate(new Date(props.participant.birthday))} </div>
            <div className="p4-medium">{props.participant.clientCustomerId} </div>
            <div className="p4-medium">{props.participant.measuresParticipant.inactive ? 'archiviert' : 'aktiv'}</div>
            <div className="icons-container">
                {props.isRemoveIconEnabled && (
                    <Icon className={'participants-management-table-delete'} type={'Close'} onClick={onClickRemove} />
                )}
            </div>
            {showRemoveModal && (
                <Modal
                    show={showRemoveModal}
                    header={
                        <div>
                            {props.participant.firstName} {props.participant.lastName} aus Maßnahme{' '}
                            {props.measure?.name} entfernen
                        </div>
                    }
                    buttons={{
                        primary: { text: 'Ja, Teilnehmer entfernen', onClick: onConfirmRemove },
                        secondary: { text: 'Abbrechen', onClick: onCancelRemove },
                        swapped: true
                    }}
                >
                    <div>
                        Sie sind dabei, einen Teilnehmer aus einer Maßnahme zu entfernen. Wenn dies die einzige Maßnahme
                        des Teilnehmers ist, wird der Teilnehmer unwideruflich gelöscht. In diesem Fall gehen alle Daten
                        dieses Teilnehmers verloren.
                    </div>
                    <div>Sind Sie sicher, dass Sie den Teilnehmer aus der Maßnahme entfernen möchten?</div>
                </Modal>
            )}
        </div>
    );
};
