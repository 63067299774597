import {
    ParticipantLivingSituation,
    ParticipantPersonalStatus,
    ParticipantWithPresence
} from '../../models/Participant/Types';
import { FormikErrors } from 'formik';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { List } from '../List/List';
import { DropDown } from '../DropDown/DropDown';
import {
    mapParticipantLivingSituation,
    mapParticipantPersonalStatus,
    participantLivingSituation,
    participantPersonalStatus
} from '../../models/Participant/Participant';
import { useAppDispatch } from '../../hooks';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { useParticipantsApi } from '../../api/useParticipantsApi';
import { participantUpdated } from '../../models/Participant/Slice';
import { useParticipants } from '../../models/Participant/Hooks';

interface ParticipantLifeInformationFormProps {
    values: ParticipantWithPresence | undefined;
    errors: FormikErrors<ParticipantWithPresence>;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
}

export const ParticipantLifeInformationForm = (props: ParticipantLifeInformationFormProps) => {
    const selectedParticipant = useParticipants((x) => x.selectedParticipant);
    const { apiUpdateParticipant } = useParticipantsApi();
    const dispatch = useAppDispatch();

    const onChange = async (
        object: Partial<{ personalStatus: ParticipantPersonalStatus; livingSituation: ParticipantLivingSituation }>
    ) => {
        if (selectedParticipant) {
            try {
                for (const [key, value] of Object.entries(object)) {
                    props.setFieldValue(key, value);
                }

                const updatedParticipant = await apiUpdateParticipant(selectedParticipant?.id, {
                    ...selectedParticipant,
                    ...object
                });

                dispatch(participantUpdated(updatedParticipant));

                showSuccessMessage();
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    return (
        <List
            options={[
                {
                    label: 'Familienstand',
                    name: 'personalStatus',
                    input: (
                        <DropDown title={<div>{mapParticipantPersonalStatus(props.values?.personalStatus)}</div>}>
                            {participantPersonalStatus.map((personalStatus, index) => {
                                return (
                                    <div key={index} onClick={() => onChange({ personalStatus: personalStatus })}>
                                        {mapParticipantPersonalStatus(personalStatus)}
                                    </div>
                                );
                            })}
                        </DropDown>
                    )
                },
                {
                    label: 'Lebensform',
                    name: 'livingSituation',
                    input: (
                        <DropDown title={<div>{mapParticipantLivingSituation(props.values?.livingSituation)}</div>}>
                            {participantLivingSituation.map((livingSituation, index) => {
                                return (
                                    <div key={index} onClick={() => onChange({ livingSituation: livingSituation })}>
                                        {mapParticipantLivingSituation(livingSituation)}
                                    </div>
                                );
                            })}
                        </DropDown>
                    )
                }
            ]}
        />
    );
};
