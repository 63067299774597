import React, { useMemo } from 'react';
import { Attendance } from '../../components/Attendance/Attendance';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import './PresenceCalendarAttendance.css';
import { dayEquals } from '../../utils/DateUtils';

/**
 * Props for the PresenceCalendarAttendance component.
 */
interface PresenceCalendarAttendanceProps {
    // The index of the day of the attendance
    dayIndex: number;

    // The participant of the attendance
    participant: ParticipantWithPresence;

    // Called if the attendance is clicked
    onClick: (
        event: React.MouseEvent<HTMLDivElement>,
        id: number | undefined,
        date: Date,
        participant: ParticipantWithPresence
    ) => void;

    // Some day in the month of the attendance
    month: Date;
}

/**
 * Shows the attendance for the participant specified in the props for the day specified by the props.
 * If the participant has no presence on the defined day, an attendance without category will be shown.
 *
 * @param props
 * @constructor
 */
export const PresenceCalendarAttendance = (props: PresenceCalendarAttendanceProps) => {
    /**
     * Returns the day defined by the month object and dayIndex as date object.
     */
    const day = useMemo(() => {
        return new Date(props.month.getFullYear(), props.month.getMonth(), props.dayIndex);
    }, [props.month, props.dayIndex]);

    /**
     * Returns the presence object of the day of this attendance, if exists. Otherwise undefined will be returned.
     */
    const presence = useMemo(() => {
        return props.participant.presences?.find((presence) => dayEquals(presence.presentAt, day));
    }, [props.participant, day]);

    return (
        <div
            className={`presence-calendar-attendance ${
                day.getDay() === 6 || day.getDay() === 0 ? 'presence-calendar-attendance-weekend' : ''
            }`}
        >
            {presence ? (
                <div
                    onClick={(event) => props.onClick(event, presence.id, day, props.participant)}
                    className={`presence-calendar-attendance`}
                >
                    <Attendance category={presence.category} />
                </div>
            ) : (
                <div
                    onClick={(event) => props.onClick(event, undefined, day, props.participant)}
                    className={`presence-calendar-attendance`}
                >
                    <Attendance category={undefined} />
                </div>
            )}
        </div>
    );
};
