import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../configuration/AppRoutes';
import { Measure } from '../../models/Measure/Types';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { UserMeasureManagementSubMenu } from '../UserMeasureManagementSubMenu/UserMeasureManagementSubMenu';
import { UserMeasureUserManagementSubMenu } from '../UserMeasureUserManagementSubMenu/UserMeasureUserManagementSubMenu';
import './AppNavigation.css';
import { useMeasures } from '../../models/Measure/Hooks';

interface AppNavigationProps {
    // call back to close the navbar
    onClickOpenSideNavigation: () => void;
}

export const AppNavigation = (props: AppNavigationProps) => {
    // get current path
    const { pathname } = useLocation();
    // state to switch the title in the app
    const [siteTitle, setSiteTitle] = useState<ReactElement | ReactElement[] | string>();

    const measure: Measure | undefined = useMeasures((x) => x.selectedMeasure);
    const location = useLocation();

    /**
     * Change title based on path in the browser
     */
    useEffect(() => {
        switch (pathname) {
            case AppRoutes.welcome:
                setSiteTitle('Maßnahmen verwalten');
                break;
            case AppRoutes.admin.projects:
                setSiteTitle('Maßnahmen verwalten');
                break;
            case AppRoutes.components:
                setSiteTitle('Komponenten');
                break;
            case AppRoutes.admin.users:
                setSiteTitle('Mitarbeiter verwalten');
                break;
            case AppRoutes.admin.qmDocuments:
                setSiteTitle('Dokumente verwalten');
                break;
            case AppRoutes.tasks:
                setSiteTitle('Aufgaben');
                break;
            case AppRoutes.projectsTab(measure?.id):
                setSiteTitle(`${measure?.name}`);
                break;
            case AppRoutes.attendanceTab(measure?.id):
                setSiteTitle(`${measure?.name}`);
                break;
            default:
                setSiteTitle(undefined);
        }
    }, [measure?.id, measure?.name, pathname]);

    /**
     * Identifies the submenu to show depending on the current path.
     */
    const subMenu = useMemo(() => {
        if (location.pathname.startsWith(AppRoutes.participantDetails(measure?.id))) {
            return 'UserManagementSubMenu';
        }

        if (location.pathname.startsWith(AppRoutes.measureManagement(measure?.id))) {
            return 'ManagementSubMenu';
        }

        return 'none';
    }, [location, measure]);

    return (
        <div className={`app-navigation ${typeof siteTitle !== 'string' && 'app-navigation-sub-menu'}`}>
            <div className="app-navigation-main">
                <div style={{ alignSelf: 'flex-start' }}>
                    <Button
                        type={'secondary'}
                        className="app-navigation-side-menu-button"
                        size={'small'}
                        buttonStyle={'link'}
                        firstIcon={<Icon type={'Menu'} />}
                        onClick={props.onClickOpenSideNavigation}
                    />
                </div>
                <div className="app-navigation-sub">
                    {subMenu === 'UserManagementSubMenu' ? (
                        <UserMeasureUserManagementSubMenu />
                    ) : subMenu === 'ManagementSubMenu' ? (
                        <UserMeasureManagementSubMenu />
                    ) : null}

                    <h6 className="site-title">{siteTitle}</h6>
                </div>
            </div>
        </div>
    );
};
