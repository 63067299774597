import { FormikErrors } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { platformUrl } from '../../helpers';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { ActiveStorageDropzone } from '../ActiveStorageDropzone/ActiveStorageDropzone';
import { Icon } from '../Icon/Icon';
import './ParticipantCvCardForm.css';
import { useParticipantsApi } from '../../api/useParticipantsApi';
import { useAppDispatch } from '../../hooks';
import { participantUpdated } from '../../models/Participant/Slice';

interface ParticipantCvCardFormProps {
    errors: FormikErrors<ParticipantWithPresence>;
    handleChange: FormikHandleChange;
    handleSubmit: () => void;
    setFieldValue: FormikSetFieldValue;
    values: ParticipantWithPresence;
    participant: ParticipantWithPresence;
}

export const ParticipantCvCardForm = (props: ParticipantCvCardFormProps) => {
    const { participant, values } = props;
    const { apiUpdateParticipant } = useParticipantsApi();
    const dispatch = useAppDispatch();

    /**
     * Backend call to update the participant and its cv information respectively
     */
    const updateParticipantCv = useCallback(async () => {
        try {
            const updatedParticipant = await apiUpdateParticipant(participant.id, values);

            dispatch(participantUpdated(updatedParticipant));

            showSuccessMessage();
        } catch (e) {
            throwError();
            console.log(e);
        }
    }, [apiUpdateParticipant, dispatch, participant.id, values]);

    /**
     * Check if cv information has changed
     */
    useEffect(() => {
        if (props.participant.curriculumVitae !== props.values.curriculumVitae) {
            (async () => {
                await updateParticipantCv();
            })();
        }
    }, [props.participant.curriculumVitae, props.values.curriculumVitae, updateParticipantCv]);

    return (
        <div>
            {props.participant.curriculumVitaeUrl && (
                <div className="participant-cv-card-form-download">
                    <a
                        download
                        target="_blank"
                        href={platformUrl(props.participant.curriculumVitaeUrl)}
                        rel="noreferrer"
                    >
                        Lebenslauf herunterladen
                    </a>
                    <Icon type={'DocumentDownload'} />
                </div>
            )}
            <ActiveStorageDropzone
                className={'qm-document-template-form-upload'}
                onChange={(files) =>
                    props.setFieldValue('curriculumVitae', files.length > 0 ? files[0].signedId : null)
                }
                attributeName="document"
                textDropzone={'Laden Sie hier eine Datei hoch'}
                files={props.values.curriculumVitae ? [{ signedId: props.values.curriculumVitae }] : []}
                multiple={false}
                maxFiles={1}
                onMaxFilesError={() => alert('Es ist nur eine Datei erlaubt')}
                maxSize={10485760}
                onMaxSizeError={() => alert('Die Datei ist zu groß. Sie darf max. 10MB groß sein.')}
            />
        </div>
    );
};
