import { ParticipantNotes } from '../ParticipantNotes/ParticipantNotes';
import './ParticipantNotesModal.css';
import { ParticipantWithPresence } from '../../../models/Participant/Types';

interface ParticipantNotesModalProps {
    participant: ParticipantWithPresence;
    onClose: () => void;
    show: boolean;
}

export const ParticipantNotesModal = (props: ParticipantNotesModalProps) => {
    return (
        <div className={'participant-notes-modal'}>
            <ParticipantNotes show={props.show} onClose={props.onClose} participant={props.participant} />
        </div>
    );
};
