import { Participant } from '../../models/Participant/Types';
import { Avatar } from '../Avatar/Avatar';
import styles from './ParticipantsListItem.module.css';
import { Icon } from '../Icon/Icon';

/**
 * Props for the ParticipantsListItem component.
 */
interface ParticipantsListItemProps {
    // The participant that is shown in the List
    participant: Participant;

    // If this is given, a remove icon will be shown that onclick will trigger this callback
    onClickRemove?: (participant: Participant) => void;
}

/**
 * Shows the participant as list entry.
 *
 * @param props
 * @constructor
 */
export const ParticipantsListItem = (props: ParticipantsListItemProps) => {
    /**
     * Called if the remove icon was clicked.
     * Calls the callback given by the props.
     */
    const onClickRemove = () => {
        if (!props.onClickRemove) return;

        props.onClickRemove(props.participant);
    };

    return (
        <div className={styles.container}>
            <div>
                {props.participant.id && (
                    <Avatar url={props.participant.avatarUrl ? props.participant.avatarUrl : null} size={'small'} />
                )}
                <div className={styles.description}>
                    {props.participant.lastName}, {props.participant.firstName}
                </div>
                {props.onClickRemove && (
                    <div>
                        <Icon type={'UserRemove'} onClick={onClickRemove} />
                    </div>
                )}
            </div>
        </div>
    );
};
