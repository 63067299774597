import { MeasureState } from '../Measure/State';
import { useAppSelector } from '../../hooks';

/**
 * Returns a value from the measure state by using the specified provider that takes the users state as parameter.
 *
 * @param mapping
 */
export const useMeasures = <T>(mapping: (state: MeasureState) => T) => {
    return useAppSelector<T>((x) => mapping(x.measure));
};
