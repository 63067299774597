import { Formik } from 'formik';
import React from 'react';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { Card } from '../Card/Card';
import { Label } from '../Label/Label';
import { ParticipantArchivedInformationForm } from '../ParticipantArchivedInformationForm/ParticipantArchivedInformationForm';

interface ParticipantArchivedInformationCardProps {
    participant: ParticipantWithPresence | undefined;
}

export const ParticipantArchivedInformationCard = (props: ParticipantArchivedInformationCardProps) => {
    const initialUserDetailsFormValues = props.participant;

    return (
        <Card key={props.participant?.id} className={'height-auto'}>
            {initialUserDetailsFormValues && (
                <Formik
                    initialValues={initialUserDetailsFormValues}
                    // We pass this submit handler because formik expects one, but we do not want to use the formik submit handling
                    onSubmit={() => console.log('Form Submit')}
                    enableReinitialize
                >
                    {({ values, errors, handleChange, setFieldValue }) => (
                        <div className="participant-details-container">
                            <div>
                                <Label className="user-details-general-title" size={2}>
                                    AUSTRITT
                                </Label>
                                <div className="participant-details">
                                    <div className="participant-details-form">
                                        <ParticipantArchivedInformationForm
                                            values={values}
                                            errors={errors}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            participant={props.participant}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Card>
    );
};
