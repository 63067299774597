import { FormikErrors } from 'formik';
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { isObjectEmpty, states } from '../../helpers';
import { formatDate } from '../../models/Date/Date';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { usePrevious } from '../../models/Hooks/Hooks';
import { mapLegalSphere } from '../../models/Measure/Measure';
import { Measure } from '../../models/Measure/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Datepicker } from '../Datepicker/Datepicker';
import { DropDown } from '../DropDown/DropDown';
import { List } from '../List/List';
import './DetailsFormList.css';
import { useMeasuresApi } from '../../api/useMeasuresApi';

interface DetailsFormListProps {
    values: Measure;
    handleChange: FormikHandleChange;
    errors: FormikErrors<Measure>;
    // Callback used when measure was updated
    onMeasureUpdated?: () => void;
    setFieldValue: FormikSetFieldValue;
    measure: Measure | undefined;
    // Is the api update callback supposed to be called when a value has changed (e.g. after onBlur)?
    isUpdateOnChangeEnabled: boolean;
}

export const DetailsFormList = (props: DetailsFormListProps) => {
    // previous value of legalSphere so that we can compare if a change has happened
    const previousValueLegalSphere: string | undefined = usePrevious(props.values.legalSphere);
    // previous value of state so that we can compare if a change has happened
    const previousValueState: string | undefined = usePrevious(props.values.state);
    // previous value for has need communities to update it in useEffect
    const previousHasNeedCommunities: boolean | undefined = usePrevious(props.values.hasNeedCommunities);
    // destructuring so that useCallback below does not rerender with every prop change
    const { apiUpdateMeasure } = useMeasuresApi();

    const onBlurUpdateUser = useCallback(
        async (event?: ChangeEvent<HTMLInputElement> | null, date?: Partial<{ startAt: Date; endAt: Date }> | null) => {
            if (!props.isUpdateOnChangeEnabled) {
                return;
            }

            // we need an additional check for date because we are using setFieldValue to change the date.
            // setFieldValue is async but there is no option to solve a promise and set a callback respectively
            // so we need to manually tell, that the date has changed by giving this function the date as an value and tell the function to proceed
            // otherwise the comparison of props.measure and props.value will always say it's still the same as the update of startAt is not done yet by Formik
            if (
                isObjectEmpty(props.errors) &&
                (date && !isObjectEmpty(date) ? true : JSON.stringify(props.measure) !== JSON.stringify(props.values))
            ) {
                try {
                    const updated = await apiUpdateMeasure(
                        date ? { ...props.values, ...date } : props.values,
                        props.values.id
                    );
                    if (updated) {
                        showSuccessMessage('Maßnahme wurde aktualisiert');
                        if (!props.onMeasureUpdated) return;
                        props.onMeasureUpdated();
                    }
                } catch (e) {
                    throwError();
                    console.log(e);
                }
            }
        },

        // This complains because "this" is available in onMeasureUpdated and possibly targeting stale props,
        // but we do not access this. Hence it is ok to exclude it here
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            apiUpdateMeasure,
            props.errors,
            props.measure,
            props.onMeasureUpdated,
            props.isUpdateOnChangeEnabled,
            props.values
        ]
    );

    /**
     * Change manually the formik state of startAt
     * @param value
     */
    const onChangeStartAt = (value: Date) => {
        props.setFieldValue('startAt', value.toString());
        onBlurUpdateUser(null, { startAt: value }).then();
    };

    /**
     * Change manually the formik state of endAt
     * @param value
     */
    const onChangeEndAt = (value: Date) => {
        props.setFieldValue('endAt', value.toString());
        onBlurUpdateUser(null, { endAt: value }).then();
    };

    /**
     * Change legalSphere in formik manually
     * @param value
     */
    const onChangeLegalSphere = (value: string) => {
        props.setFieldValue('legalSphere', value);
    };

    const onChangeStartAtManually = (date: Date | string) => {
        props.setFieldValue('startAt', date);
    };

    const onChangeStateManually = (state: string) => {
        props.setFieldValue('state', state);
    };

    /**
     * Called if the value of hasNeedCommunities has changed. Submits the change to the props change handler.
     *
     * @param value
     */
    const onChangeHasNeedCommunities = (value: boolean) => {
        props.setFieldValue('hasNeedCommunities', value);
    };

    /**
     * Check if legalSphere or state value has changed, if so send the new user object to the backend
     */
    useEffect(() => {
        if (
            previousValueLegalSphere !== props.values.legalSphere ||
            previousValueState !== props.values.state ||
            previousHasNeedCommunities !== props.values.hasNeedCommunities
        ) {
            onBlurUpdateUser().then();
        }
    }, [
        onBlurUpdateUser,
        previousValueLegalSphere,
        previousValueState,
        previousHasNeedCommunities,
        props.values.legalSphere,
        props.values.state,
        props.values.hasNeedCommunities
    ]);

    /**
     * When pressed enter, trigger the blur event
     * @param event
     */
    const onKeyEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    return (
        <List
            key={props.values.id}
            readonly={!!props.measure?.archived}
            options={[
                {
                    label: 'Name',
                    value: props.values.name,
                    name: 'name',
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.name,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Nr',
                    value: props.values.number,
                    name: 'number',
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.number,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Bewilligungsanfang',
                    input: props.measure?.archived ? (
                        <div className={`details-form-list-calendar ${props.measure?.archived && 'archived'}`}>
                            <input readOnly value={formatDate(new Date(props.values.startAt))} />
                        </div>
                    ) : (
                        <Datepicker
                            value={new Date(props.values.startAt)}
                            onChange={onChangeStartAt}
                            onBlur={onBlurUpdateUser}
                            returnInputFieldDate={onChangeStartAtManually}
                        >
                            {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                <div className="details-form-list-calendar" onClick={openDatepicker}>
                                    <input
                                        className={'editable-item'}
                                        value={formatDate(new Date(props.values.startAt))}
                                        onChange={onChangeDateInForm}
                                        onPaste={onPasteDate}
                                        onKeyDown={onPressEnterSubmit}
                                    />
                                </div>
                            )}
                        </Datepicker>
                    )
                },
                {
                    label: 'Bewilligungsende',
                    input: props.measure?.archived ? (
                        <div className={`details-form-list-calendar ${props.measure?.archived && 'archived'}`}>
                            <input readOnly value={formatDate(new Date(props.values.endAt))} />
                        </div>
                    ) : (
                        <Datepicker
                            value={new Date(props.values.endAt)}
                            onChange={onChangeEndAt}
                            onBlur={onBlurUpdateUser}
                        >
                            {(onChangeDateInForm, onPasteDate, onPressEnterSubmit, openDatepicker) => (
                                <div className="details-form-list-calendar" onClick={openDatepicker}>
                                    <input
                                        className={'editable-item'}
                                        value={formatDate(new Date(props.values.endAt))}
                                        onChange={onChangeDateInForm}
                                        onPaste={onPasteDate}
                                        onKeyDown={onPressEnterSubmit}
                                    />
                                </div>
                            )}
                        </Datepicker>
                    )
                },
                {
                    label: 'Straße und Hausnummer',
                    name: 'streetAndNumber',
                    value: props.values.streetAndNumber,
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.streetAndNumber,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Postleitzahl',
                    name: 'zipCode',
                    value: props.values.zipCode,
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.zipCode,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Ort',
                    name: 'city',
                    value: props.values.city,
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.city,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Bundesland',
                    name: 'state',
                    input: (
                        <DropDown
                            title={props.values.state}
                            titleClassName="dropdown-list-title"
                            disabled={props.measure?.archived}
                            className={'dropdown-list'}
                        >
                            {states.map((state) => {
                                return (
                                    <div key={state.id} onClick={() => onChangeStateManually(state.id)}>
                                        {state.value}
                                    </div>
                                );
                            })}
                        </DropDown>
                    ),
                    error: props.errors.state
                },
                {
                    label: 'Telefon',
                    name: 'phone',
                    value: props.values.phone,
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.phone,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Fax',
                    name: 'fax',
                    value: props.values.fax,
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.fax,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'E-Mail',
                    name: 'email',
                    value: props.values.email,
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.email,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Losgröße',
                    name: 'lotSize',
                    value: props.values.lotSize,
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.lotSize,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Rehaplätze',
                    name: 'rehabSlots',
                    value: props.values.rehabSlots,
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.rehabSlots,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Rechtskreis',
                    error: props.errors.legalSphere,
                    input: props.measure?.archived ? (
                        <div className="editable-item-archived">{mapLegalSphere(props.values.legalSphere)}</div>
                    ) : (
                        <DropDown
                            title={<div>{mapLegalSphere(props.values.legalSphere)}</div>}
                            titleClassName="dropdown-list-title"
                            className={'dropdown-list'}
                        >
                            <div onClick={() => onChangeLegalSphere('sgb_2')}>SGB II</div>
                            <div onClick={() => onChangeLegalSphere('sgb_3')}>SGB III</div>
                        </DropDown>
                    )
                },
                {
                    label: 'Ansprechpartner',
                    name: 'contact',
                    value: props.values.contact,
                    onChange: props.handleChange,
                    onBlur: onBlurUpdateUser,
                    error: props.errors.contact,
                    onKeyDown: onKeyEnter
                },
                {
                    label: 'Hat Bedarfsgemeinschaften',
                    error: props.errors.hasNeedCommunities,
                    input: (
                        <DropDown
                            title={props.values.hasNeedCommunities ? 'Ja' : 'Nein'}
                            titleClassName="dropdown-list-title"
                            className={'dropdown-list'}
                        >
                            <div onClick={() => onChangeHasNeedCommunities(true)}>Ja</div>
                            <div onClick={() => onChangeHasNeedCommunities(false)}>Nein</div>
                        </DropDown>
                    )
                }
            ]}
        />
    );
};
