import { ChangeEvent, useEffect, useState } from 'react';
import useSWR from 'swr';
import { Button } from '../../components/Button/Button';
import { CourseDetails } from '../../components/CourseDetails/CourseDetails';
import { Icon } from '../../components/Icon/Icon';
import { Input } from '../../components/Input/Input';
import { NewCourseDetails } from '../../components/NewCourseDetails/NewCourseDetails';
import { ObjectList } from '../../components/ObjectList/ObjectList';
import { ObjectListItem } from '../../components/ObjectListItem/ObjectListItem';
import { StandardView } from '../../components/StandardView/StandardView';
import { Course, CourseSubmit } from '../../models/Course/Type';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { CourseListItem } from '../../components/CourseListItem/CourseListItem';
import { useCoursesApi } from '../../api/useCourseApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useMeasures } from '../../models/Measure/Hooks';

export const Courses = () => {
    const measureId = useMeasures((x) => x.selectedMeasure?.id);
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: courses, mutate: reloadCourses } = useSWR<Course[]>([
        backendUrl,
        `measures/${measureId}/courses`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: allUsers } = useSWR([backendUrl, 'users', 'GET', null, authInfo, apiVersion]);
    const { data: allParticipants } = useSWR([
        backendUrl,
        measureId ? `measures/${measureId}/active_participants` : null,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [selectedCourse, setSelectedCourse] = useState<Course | CourseSubmit>();
    const [createNewCourse, setCreateNewCourse] = useState<CourseSubmit>();
    const [search, setSearch] = useState<boolean>(false);
    const [filteredCourses, setFilteredCourses] = useState<Course[] | undefined>([]);
    const { apiAddParticipantToCourse, apiAddUserToCourse, apiCreateCourse } = useCoursesApi();

    const onSelectCompany = (index: number | string, course: Course | CourseSubmit) => {
        setSelectedCourse(course);
    };

    const onCreateNewCourse = () => {
        if (!measureId) return;

        setCreateNewCourse({
            name: 'Neues Qualifzierungsangebot',
            date: new Date(),
            category: 'educational_basic_qualification',
            description: '',
            archived: false,
            measureId: measureId
        });
    };

    /**
     * if a new course should be created, select it so that the user knows it is selected
     */
    useEffect(() => {
        if (createNewCourse) {
            setSelectedCourse(createNewCourse);
        }
    }, [createNewCourse]);

    /**
     * Submit new course
     * @param values
     */
    const onSubmit = async (values: CourseSubmit) => {
        try {
            const newCourse = await apiCreateCourse(values);
            // if users were already added, assign them
            if (values.users) {
                await Promise.all(
                    values.users.map((user) => {
                        return apiAddUserToCourse(newCourse.id, user.id);
                    })
                );
            }

            // if participants were already added, assign them
            if (values.participants) {
                await Promise.all(
                    values.participants.map((user) => {
                        return apiAddParticipantToCourse(newCourse.id, user.id);
                    })
                );
            }

            reloadCourses().then();
            showSuccessMessage();
            setSelectedCourse(newCourse);
            setCreateNewCourse(undefined);
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * When courses were fetched, set them as the filtered courses
     */
    useEffect(() => {
        setFilteredCourses(courses?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()));
    }, [courses]);

    /**
     * Open/close the search bar
     */
    const onClickOpenSearch = () => {
        setSearch(!search);
    };

    /**
     * Search function to filter for search input
     * @param event
     */
    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setFilteredCourses(
            courses?.filter((course) => course.name.toLowerCase().includes(event.target.value.toLowerCase()))
        );
    };

    /**
     * When no course is selected, select the first course
     */
    useEffect(() => {
        if (!selectedCourse && courses) {
            setSelectedCourse(courses[0]);
        }
    }, [courses, selectedCourse]);

    return (
        <>
            <StandardView>
                <StandardView.Left>
                    <ObjectList className={'width-auto'}>
                        <ObjectList.Head>
                            <div className="measure-list-head-container" style={{ marginBottom: '24px' }}>
                                <div className="measure-list-head">
                                    <div className="p2-medium">Qualifizierungsangebote</div>
                                    <div className="measure-list-head-search">
                                        <Button
                                            type={'secondary'}
                                            buttonStyle={'link'}
                                            size={'small'}
                                            firstIcon={<Icon type={'Search'} />}
                                            onClick={onClickOpenSearch}
                                        />
                                        <Button
                                            type={'primary'}
                                            size={'small'}
                                            buttonStyle={'filled'}
                                            firstIcon={<Icon type={'Plus'} />}
                                            onClick={onCreateNewCourse}
                                        />
                                    </div>
                                </div>
                                {search && (
                                    <Input
                                        icon={<Icon type={'Search'} />}
                                        placeholder={'Angebot suchen'}
                                        onChange={onChangeSearch}
                                    />
                                )}
                            </div>
                        </ObjectList.Head>
                        <ObjectList.Body>
                            <div className="participant-detail-information-dates" style={{ flexDirection: 'column' }}>
                                {createNewCourse && (
                                    <ObjectListItem
                                        value={{ index: -1, item: createNewCourse }}
                                        selected={selectedCourse === createNewCourse}
                                        onClick={onSelectCompany}
                                    >
                                        <CourseListItem course={createNewCourse} />
                                    </ObjectListItem>
                                )}
                                {filteredCourses?.map((course, index) => {
                                    return (
                                        <ObjectListItem
                                            key={course.id}
                                            value={{ index: index, item: course }}
                                            selected={selectedCourse === course}
                                            onClick={onSelectCompany}
                                        >
                                            <CourseListItem course={course} />
                                        </ObjectListItem>
                                    );
                                })}
                            </div>
                        </ObjectList.Body>
                    </ObjectList>
                </StandardView.Left>
                <StandardView.Right>
                    <>
                        {selectedCourse && 'id' in selectedCourse ? (
                            <CourseDetails
                                reloadCourses={reloadCourses}
                                allParticipants={allParticipants}
                                allUsers={allUsers}
                                course={selectedCourse}
                            />
                        ) : createNewCourse ? (
                            <NewCourseDetails
                                allParticipants={allParticipants}
                                allUsers={allUsers}
                                onSubmit={onSubmit}
                                course={createNewCourse}
                            />
                        ) : null}
                    </>
                </StandardView.Right>
            </StandardView>
        </>
    );
};
