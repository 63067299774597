import React, { ChangeEvent, useEffect, useState } from 'react';
import useSWR from 'swr';
import { Internship } from '../../models/Internship/Types';
import { QmDocument, QmDocumentDocx } from '../../models/QmDocument/Types';
import { QmDocumentTemplate } from '../../models/QmDocumentTemplate/Types';
import { Button } from '../Button/Button';
import { FullScreenModal } from '../FullScreenModal/FullScreenModal';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { ObjectList } from '../ObjectList/ObjectList';
import { ObjectListItem } from '../ObjectListItem/ObjectListItem';
import { Download } from '../PrintQmDocumentModal/PrintQmDocumentModal';
import { QmDocumentDetails } from '../QmDocumentDetails/QmDocumentDetails';
import { SelectInternshipForQmDocuments } from '../SelectInternshipForQmDocuments/SelectInternshipForQmDocuments';
import { StandardView } from '../StandardView/StandardView';
import './QmDocumentModal.css';
import { useQmDocumentsApi } from '../../api/useQmDocumentsApi';
import { useParticipantsApi } from '../../api/useParticipantsApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useMeasures } from '../../models/Measure/Hooks';
import { useParticipants } from '../../models/Participant/Hooks';

interface QmDocumentModalProps {
    onClose: () => void;
    show: boolean;
}

export const QmDocumentModal = (props: QmDocumentModalProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const measureId = useMeasures((x) => x.selectedMeasure?.id);
    const participantId = useParticipants((x) => x.selectedParticipant?.id);
    const [qmDocuments, setQmDocuments] = useState<QmDocument[]>();
    const [selectedQmDocument, setSelectedQmDocument] = useState<QmDocument>();
    const [printableDocument, setPrintableDocument] = useState<QmDocumentDocx>();
    const [internshipsForQmDocument, setInternshipsForQmDocument] = useState<Internship[]>();
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [filteredQmDocuments, setFilteredQmDocuments] = useState<QmDocument[]>();
    const { data: qmDocumentTemplates } = useSWR<QmDocumentTemplate[]>([
        backendUrl,
        'qm_document_templates',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { apiSearchQmDocumentsForPrinting, apiUpdateQmDocument, apiCreateQmDocumentDocx } = useQmDocumentsApi();
    const { apiGetInternshipByParticipantId } = useParticipantsApi();

    /**
     * Requests the document that should be printed
     */
    useEffect(() => {
        (async () => {
            if (measureId && qmDocumentTemplates) {
                const requestedDocuments = await Promise.all(
                    qmDocumentTemplates.map((template) => apiSearchQmDocumentsForPrinting(measureId, template.id))
                );
                const allDocuments = requestedDocuments.map((requestedDocument) => requestedDocument.results);
                setQmDocuments(allDocuments.flat());
            }
        })();
    }, [apiSearchQmDocumentsForPrinting, measureId, qmDocumentTemplates]);

    /**
     * Call back to select an qm document
     * @param index
     * @param qmDocument
     */
    const onSelectQmDocument = (index: string | number, qmDocument: QmDocument) => {
        setSelectedQmDocument(qmDocument);
    };

    const checkInternships = async () => {
        if (participantId) {
            try {
                return await apiGetInternshipByParticipantId(participantId);
            } catch (e) {
                console.log(e);
            }
        }
    };

    /**
     * call back and api call to get the corresponding docx in order to print it
     */
    const onPrintDocument = async () => {
        // check if the qm document's template includes internship information
        if (
            qmDocumentTemplates?.find(
                (qmDocumentTemplate) => qmDocumentTemplate.id === selectedQmDocument?.qmDocumentTemplateId
            )?.hasInternshipCompanyFields
        ) {
            // request internships of participant
            const internships = await checkInternships();
            if (internships && internships.length > 1) {
                // if more than one internship show the select internship modal
                setInternshipsForQmDocument(internships);
            } else if (selectedQmDocument && internships && participantId && internships.length === 1) {
                // if there's one internship, connect the qm document and print it
                try {
                    await apiUpdateQmDocument(selectedQmDocument.id, {
                        ...selectedQmDocument,
                        ...{
                            participantId: participantId,
                            internshipId: internships[0].id,
                            internshipCompanyId: internships[0].internshipCompanyId
                        }
                    });
                    setPrintableDocument(await apiCreateQmDocumentDocx(selectedQmDocument?.id));
                } catch (e) {
                    console.log(e);
                }
            } else {
                // if there's no internship information remove any existing connection to internships before printing
                try {
                    if (selectedQmDocument && participantId) {
                        await apiUpdateQmDocument(selectedQmDocument.id, {
                            ...selectedQmDocument,
                            ...{
                                participantId: participantId,
                                internshipId: null,
                                internshipCompanyId: null
                            }
                        });
                        setPrintableDocument(await apiCreateQmDocumentDocx(selectedQmDocument?.id));
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        } else {
            // qm document does not have any internship information. nevertheless, we remove the connection from qm document to an internship before printing
            try {
                if (selectedQmDocument && participantId) {
                    await apiUpdateQmDocument(selectedQmDocument.id, {
                        ...selectedQmDocument,
                        ...{
                            participantId: participantId,
                            internshipId: null,
                            internshipCompanyId: null
                        }
                    });
                    setPrintableDocument(await apiCreateQmDocumentDocx(selectedQmDocument?.id));
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    /**
     * CLose the modal to select from internships
     */
    const onCloseInternshipForQmDocumentsModal = () => {
        setInternshipsForQmDocument(undefined);
    };

    /**
     * Close modal and delete everything
     */
    const onClose = () => {
        setSelectedQmDocument(undefined);
        setPrintableDocument(undefined);
        props.onClose();
    };

    /**
     * Show search field
     */
    const onClickOpenSearch = () => {
        setShowSearch(!showSearch);
    };

    /**
     * onChange function for search
     * @param event
     * @param value
     */
    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setFilteredQmDocuments(
            qmDocuments?.filter((qmDocument) =>
                qmDocument.name.toLowerCase().includes(event.target.value.toLowerCase() as string)
            )
        );
    };

    /**
     * set an qm select on modal start
     */
    useEffect(() => {
        if (!selectedQmDocument && qmDocuments) {
            setSelectedQmDocument(qmDocuments[0]);
        }
    }, [qmDocuments, selectedQmDocument]);

    /**
     * Set qm documents to the filtered component after first request
     */
    useEffect(() => {
        setFilteredQmDocuments(qmDocuments);
    }, [qmDocuments]);

    return props.show ? (
        <FullScreenModal
            footer={
                <>
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        text={'Abbrechen'}
                        onClick={onClose}
                    />
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'filled'}
                        text={'Dokument drucken'}
                        onClick={onPrintDocument}
                    />
                </>
            }
            bodyClassName={'qm-document-modal'}
        >
            <StandardView>
                <StandardView.Left>
                    <ObjectList>
                        <ObjectList.Head>
                            <div>
                                <div className={'user-list-head'}>
                                    <div className="p2-medium">QM-Dokumente</div>
                                    <Button
                                        type={'secondary'}
                                        buttonStyle={'link'}
                                        size={'small'}
                                        firstIcon={<Icon type={'Search'} />}
                                        onClick={onClickOpenSearch}
                                    />
                                </div>
                                {showSearch && (
                                    <Input
                                        icon={<Icon type={'Search'} />}
                                        placeholder={'QM-Dokument suchen'}
                                        onChange={onChangeSearch}
                                    />
                                )}
                            </div>
                        </ObjectList.Head>
                        <ObjectList.Body>
                            {filteredQmDocuments?.map((qmDocument, index) => {
                                return (
                                    <ObjectListItem
                                        key={qmDocument.id}
                                        value={{ index: index, item: qmDocument }}
                                        selected={selectedQmDocument === qmDocument}
                                        onClick={onSelectQmDocument}
                                    >
                                        <div>
                                            {
                                                qmDocumentTemplates?.find(
                                                    (template) => template.id === qmDocument?.qmDocumentTemplateId
                                                )?.name
                                            }
                                        </div>
                                    </ObjectListItem>
                                );
                            })}
                        </ObjectList.Body>
                    </ObjectList>
                </StandardView.Left>
                <StandardView.Right>
                    <>
                        {selectedQmDocument && (
                            <QmDocumentDetails
                                qmDocumentTemplate={qmDocumentTemplates?.find(
                                    (template) => template.id === selectedQmDocument?.qmDocumentTemplateId
                                )}
                                qmDocument={selectedQmDocument}
                            />
                        )}
                        {printableDocument && <Download documents={[printableDocument]} />}
                        {internshipsForQmDocument && participantId && selectedQmDocument && (
                            <SelectInternshipForQmDocuments
                                show={!!internshipsForQmDocument}
                                internships={internshipsForQmDocument}
                                onClose={onCloseInternshipForQmDocumentsModal}
                                participantId={participantId}
                                selectedQmDocument={selectedQmDocument}
                                setPrintableDocument={setPrintableDocument}
                            />
                        )}
                    </>
                </StandardView.Right>
            </StandardView>
        </FullScreenModal>
    ) : null;
};
