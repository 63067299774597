import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import useSWR from 'swr';
import { AppNavigation } from '../../components/AppNavigation/AppNavigation';
import { ParticipantNotesWrapper } from '../../components/ParticipantNotesWrapper/ParticipantNotesWrapper';
import { SideBar } from '../../components/SideBar/SideBar';
import { AppRoutes } from '../../configuration/AppRoutes';
import { useAppDispatch } from '../../hooks';
import { setSelectedMeasure } from '../../models/Measure/Slice';
import { Measure } from '../../models/Measure/Types';
import { useUser } from '../../models/User/State';
import { AdminMeasureTab } from '../AdminMeasureTab/AdminMeasureTab';
import { AdminQMDocuments } from '../AdminQMDocuments/AdminQMDocuments';
import { AdminUserTab } from '../AdminUserTab/AdminUserTab';
import { UserMeasureAttendanceManagement } from '../UserMeasureAttendanceManagement/UserMeasureAttendanceManagement';
import { UserMeasureParticipantManagement } from '../UserMeasureParticipantManagement/UserMeasureParticipantManagement';
import { UserTasks } from '../UserTasks/UserTasks';
import './AppContainer.css';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useSession } from '../../hooks/useSession';
import { LoginPage } from '../LoginPage/LoginPage';
import { LostPasswordPage } from '../LostPasswordPage/LostPasswordPage';
import { ParticipantDetailInformationViewSupportPlans } from '../../components/ParticipantDetailInformationViewSupportPlans/ParticipantDetailInformationViewSupportPlans';
import { ParticipantDetailInformationViewNeedForActions } from '../../components/ParticipantDetailInformationViewNeedForActions/ParticipantDetailInformationViewNeedForActions';
import { ParticipantDetailInformationViewInformation } from '../../components/ParticipantDetailInformationViewInformation/ParticipantDetailInformationViewInformation';
import { ParticipantDetailInformationViewContacts } from '../ParticipantDetailInformationViewContacts/ParticipantDetailInformationViewContacts';
import { CompaniesManagement } from '../CompaniesManagement/CompaniesManagement';
import { Letters } from '../Letters/Letters';
import { MeasureStatistics } from '../MeasureStatistics/MeasureStatistics';
import { Courses } from '../Courses/Courses';
import { NeedCommunitiesPage } from '../NeedCommunitiesPage/NeedCommunitiesPage';
import { useMeasures } from '../../models/Measure/Hooks';
import { useParticipants } from '../../models/Participant/Hooks';

/**
 * Page that shows up a welcome screen.
 * This is the entry point of the app. If the user is not signed in, he will be redirected to the login page.
 */
export const AppContainer = () => {
    const measureId = useMeasures((x) => x.selectedMeasure?.id);
    const [openSideNavigation, setOpenSideNavigation] = useState<boolean>(false);
    const selectedParticipant = useParticipants((x) => x.selectedParticipant);
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { apiGetMeasureById } = useMeasuresApi();
    const curretUser = useUser((x) => x.currentUser);
    const { isAdministrator, isQmManager } = useSession();

    const { data: activeMeasures } = useSWR<Measure[]>([
        backendUrl,
        `users/${curretUser?.id}/active_in_measures`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * If there is an existing id but currently no measure in the redux state, fetch the measure by id and set it
     * to the state
     */
    useEffect(() => {
        (async () => {
            if (id && !measureId) {
                dispatch(setSelectedMeasure(await apiGetMeasureById(parseInt(id))));
            }
        })();
    }, [apiGetMeasureById, dispatch, id, measureId]);

    /**
     * Callback to open the side navigation
     */
    const onClickOpenSideNavigation = () => {
        setOpenSideNavigation(true);
    };

    /**
     * Callback to close the side navigation
     */
    const onClickCloseSideNavigation = () => {
        setOpenSideNavigation(false);
    };

    return (
        <div className="welcome-page">
            {authInfo && (
                <SideBar
                    openNavigation={openSideNavigation}
                    activeMeasures={activeMeasures
                        ?.filter((measure) => !measure.archived)
                        .sort((a, b) => a.name.localeCompare(b.name))}
                    onClickCloseSideNavigation={onClickCloseSideNavigation}
                />
            )}
            <div className="app-container">
                {authInfo && <AppNavigation onClickOpenSideNavigation={onClickOpenSideNavigation} />}
                <div className="app-container-routes">
                    <Routes>
                        {/* Starting page */}
                        <Route
                            path="/"
                            element={authInfo ? <Navigate to={AppRoutes.tasks} /> : <Navigate to={AppRoutes.login} />}
                        />
                        <Route
                            path={AppRoutes.login}
                            element={authInfo ? <Navigate to={AppRoutes.tasks} /> : <LoginPage />}
                        />
                        <Route path={AppRoutes.lostPassword} element={<LostPasswordPage />} />

                        {/* Starting page */}
                        <Route
                            path="/"
                            element={authInfo ? <Navigate to={AppRoutes.tasks} /> : <Navigate to={AppRoutes.login} />}
                        />
                        <Route
                            path={AppRoutes.login}
                            element={authInfo ? <Navigate to={AppRoutes.tasks} /> : <LoginPage />}
                        />
                        <Route path={AppRoutes.lostPassword} element={<LostPasswordPage />} />

                        <Route path={AppRoutes.tasks} element={<UserTasks />} />
                        {isAdministrator() ? (
                            <>
                                <Route path={AppRoutes.admin.projects} element={<AdminMeasureTab />} />
                                <Route path={AppRoutes.admin.qmDocuments} element={<AdminQMDocuments />} />
                                <Route path={AppRoutes.admin.users} element={<AdminUserTab />} />
                                <Route
                                    path={AppRoutes.projectsTab(measureId)}
                                    element={<UserMeasureParticipantManagement />}
                                />
                                <Route
                                    path={AppRoutes.attendanceTab(measureId)}
                                    element={<UserMeasureAttendanceManagement />}
                                />
                            </>
                        ) : isQmManager() ? (
                            <Route path={AppRoutes.admin.qmDocuments} element={<AdminQMDocuments />} />
                        ) : null}

                        <Route
                            path={AppRoutes.participantDetailsSupportPlan()}
                            element={<ParticipantDetailInformationViewSupportPlans participant={selectedParticipant} />}
                        />
                        <Route
                            path={AppRoutes.participantDetailsNeedForAction()}
                            element={
                                <ParticipantDetailInformationViewNeedForActions participant={selectedParticipant} />
                            }
                        />
                        {selectedParticipant && (
                            <Route
                                path={AppRoutes.participantDetailsInformation()}
                                element={
                                    <ParticipantDetailInformationViewInformation participant={selectedParticipant} />
                                }
                            />
                        )}
                        <Route
                            path={AppRoutes.participantDetailsContacts()}
                            element={<ParticipantDetailInformationViewContacts participant={selectedParticipant} />}
                        />

                        <Route path={AppRoutes.projectsTab(measureId)} element={<UserMeasureParticipantManagement />} />
                        <Route
                            path={AppRoutes.attendanceTab(measureId)}
                            element={<UserMeasureAttendanceManagement />}
                        />
                        <Route path={AppRoutes.measureCompanies(measureId)} element={<CompaniesManagement />} />
                        <Route path={AppRoutes.measureLetterTemplates(measureId)} element={<Letters />} />
                        <Route path={AppRoutes.measureStatistics(measureId)} element={<MeasureStatistics />} />
                        <Route path={AppRoutes.measureCourses(measureId)} element={<Courses />} />
                        <Route path={AppRoutes.measureNeedCommunities(measureId)} element={<NeedCommunitiesPage />} />
                    </Routes>
                </div>
            </div>
            <ParticipantNotesWrapper />
        </div>
    );
};
