import { ReactElement, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { Indicator } from '../Indicator/Indicator';
import './SidebarItem.css';

interface SidebarItemProps {
    type: 'primary' | 'secondary';
    indicator?: number;
    iconFront: ReactElement;
    iconBack?: ReactElement;
    text: string;
    children?: ReactNode;
    onClick?: (arg?: string) => void;
    isSelected?: boolean;
    link?: string;
    isOldMeasure?: boolean;
}

export const SidebarItem = (props: SidebarItemProps) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);

    const onClickItem = () => {
        if (props.isSelected === undefined) {
            setIsClicked(!isClicked);
        }

        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <div>
            {props.link ? (
                <Link to={props.link ? props.link : ''}>
                    <div className="side-bar-item-container">
                        <div
                            className={`side-bar-item ${props.type} ${(isClicked || props.isSelected) && 'active'}`}
                            onClick={onClickItem}
                        >
                            <div className="side-bar-item-icon-text">
                                <div>{props.iconFront}</div>
                                <div className="p4-medium truncate">{props.text}</div>
                                {props.isOldMeasure && (
                                    <div style={{ position: 'absolute', bottom: '-15px', left: 0 }}>(alt)</div>
                                )}
                                {props.indicator && <Indicator indicator={props.indicator} />}
                            </div>
                            <div>{props.iconBack}</div>
                        </div>
                        {(isClicked || props.isSelected) && (
                            <div className="side-bar-item-children">
                                {Array.isArray(props.children) ? (
                                    props.children?.map((child, index) => {
                                        return <div key={index}>{child}</div>;
                                    })
                                ) : (
                                    <div>{props.children}</div>
                                )}
                            </div>
                        )}
                    </div>
                </Link>
            ) : (
                <div className="side-bar-item-container">
                    <div
                        className={`side-bar-item ${props.type} ${(isClicked || props.isSelected) && 'active'}`}
                        onClick={onClickItem}
                    >
                        <div className="side-bar-item-icon-text">
                            <div>{props.iconFront}</div>
                            <div className="p4-medium truncate">{props.text}</div>
                            {props.isOldMeasure && (
                                <div style={{ position: 'absolute', bottom: '-15px', left: 0 }}>(alt)</div>
                            )}
                            {props.indicator && <Indicator indicator={props.indicator} />}
                        </div>
                        <div>{props.iconBack}</div>
                    </div>
                    {(isClicked || props.isSelected) && (
                        <div className="side-bar-item-children">
                            {Array.isArray(props.children) ? (
                                props.children?.map((child, index) => {
                                    return <div key={index}>{child}</div>;
                                })
                            ) : (
                                <div>{props.children}</div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
