import React, { ChangeEvent, FocusEvent } from 'react';
import './EditableItem.css';

interface EditableItemProps {
    // field value
    value?: string | number | Date | boolean;
    // call back if user leaves the field
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    // call back if user changes something
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    // input field nam, mostly used for formik
    name?: string;
    // if the field is read only
    readOnly?: boolean;
    // custom cass classname
    className?: string;
    // error messagge
    onClick?: () => void;
    error?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const EditableItem = (props: EditableItemProps) => {
    /**
     * If user leaves the field, return the input value
     */
    const onBlurInput = (event: FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    return (
        <>
            {props.readOnly ? (
                <div className={`editable-readonly-item ${props.className}`} onClick={props.onClick}>
                    {props.value?.toString()}{' '}
                </div>
            ) : (
                <input
                    onKeyDown={props.onKeyDown}
                    onClick={props.onClick}
                    name={props.name}
                    value={props.value?.toString()}
                    className={`editable-item ${props.className} ${props.error ? 'error' : ''}`}
                    onChange={props.onChange}
                    onBlur={onBlurInput}
                />
            )}
        </>
    );
};
