import { useNeedCommunities } from '../../models/NeedCommunity/Hooks';
import { ObjectList } from '../ObjectList/ObjectList';
import { useAppDispatch } from '../../hooks';
import { needCommunityCreated, setSelectedNeedCommunity } from '../../models/NeedCommunity/Slice';
import { NeedCommunity } from '../../models/NeedCommunity/Types';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { useNeedCommunitiesApi } from '../../api/useNeedCommunitiesApi';
import { useMeasures } from '../../models/Measure/Hooks';
import styles from './NeedCommunitiesList.module.css';

/**
 * Shows a list of need communities that can be selected.
 *v
 * @constructor
 */
export const NeedCommunitiesList = () => {
    const selectedMeasure = useMeasures((x) => x.selectedMeasure);
    const needCommunities = useNeedCommunities((x) => x.needCommunities);
    const selectedNeedCommunity = useNeedCommunities((x) => x.selectedNeedCommunity);
    const dispatch = useAppDispatch();
    const { apiCreateNeedCommunity } = useNeedCommunitiesApi();

    /**
     * Called if the user clicks on a need community. Sets the need community as the selected one.
     */
    const onClickNeedCommunity = (index: string | number, needCommunity: NeedCommunity) => {
        dispatch(setSelectedNeedCommunity(needCommunity));
    };

    /**
     * Creates an empty need community and selects it to enable editing.
     */
    const onClickCreateCommunity = async () => {
        if (!selectedMeasure) return;

        const needCommunity = await apiCreateNeedCommunity({
            measureId: selectedMeasure.id,
            name: 'Neue Bedarfsgemeinschaft',
            identifier: 'BG-Neu'
        });

        dispatch(needCommunityCreated(needCommunity));
    };

    return (
        <ObjectList>
            <ObjectList.Head>
                <div className={styles.headContainer}>
                    <div>
                        <div className="p2-medium">Bedarfsgemeinschaften</div>
                        <div className={styles.headButtonsContainer}>
                            <Button
                                type={'primary'}
                                size={'small'}
                                buttonStyle={'filled'}
                                firstIcon={<Icon type={'Plus'} />}
                                onClick={onClickCreateCommunity}
                            />
                        </div>
                    </div>
                </div>
            </ObjectList.Head>
            <ObjectList.Body>
                <div className={styles.bodyContainer}>
                    {needCommunities?.map((item, index) => {
                        return (
                            <ObjectList.Item
                                key={index}
                                onClick={onClickNeedCommunity}
                                value={{ index, item }}
                                selected={selectedNeedCommunity?.id === item.id}
                            >
                                <div className={styles.itemContainer}>
                                    <div className={styles.itemDescription}>
                                        <div className={item.closed ? styles.nameClosed : ''}>{item.name}</div>
                                    </div>
                                </div>
                            </ObjectList.Item>
                        );
                    })}
                </div>
            </ObjectList.Body>
        </ObjectList>
    );
};
