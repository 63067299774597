import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useState } from 'react';
import useSWR from 'swr';
import { LoadingView } from '../../components/LoadingView/LoadingView';
import { MeasureList } from '../../components/MeasureList/MeasureList';
import { Measure, MeasureSubmit } from '../../models/Measure/Types';
import { QmDocumentTemplate } from '../../models/QmDocumentTemplate/Types';
import { throwError } from '../../models/Toasts/Toasts';
import { CreateMeasure } from '../AdminCreateMeasure/CreateMeasure';
import { MeasureInformation } from '../MeasureInformation/MeasureInformation';
import './AdminMeasureTab.css';
import { useMeasuresApi } from '../../api/useMeasuresApi';
import { useQmDocumentsApi } from '../../api/useQmDocumentsApi';
import { useMeasures } from '../../models/Measure/Hooks';
import { useAppDispatch } from '../../hooks';
import { setSelectedMeasure } from '../../models/Measure/Slice';

export const AdminMeasureTab = () => {
    const [createMeasure, setCreateMeasure] = useState<boolean>(false);
    const [wasSubmitted, setWasSubmitted] = useState<boolean>(true);
    const [submitError, setSubmitError] = useState<string>('');
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { apiCreateMeasure, apiAddUserToMeasure } = useMeasuresApi();
    const { apiAddQmDocumentToMeasure } = useQmDocumentsApi();
    const selectedMeasure = useMeasures((x) => x.selectedMeasure);
    const dispatch = useAppDispatch();

    /**
     * Fetch of all measures
     */
    const { data: measures, mutate } = useSWR<Measure[]>([backendUrl, 'measures', 'GET', null, authInfo, apiVersion]);

    const resetDataSelected = () => {
        if (measures) {
            const firstNotArchivedMeasure = measures.find((measure) => !measure.archived);

            dispatch(setSelectedMeasure(firstNotArchivedMeasure));
        }
    };

    /**
     * Show the create measure view
     */
    const onOpenCloseCreateMeasure = () => {
        setCreateMeasure(!createMeasure);
        setSubmitError('');
    };

    /**
     * Create a new measure
     * @param measure
     */
    const onSubmitMeasure = async (measure: MeasureSubmit) => {
        setWasSubmitted(false);
        let userSubmit = true;
        let qmDocumentsSubmit = true;
        try {
            const createMeasure = await apiCreateMeasure(measure);
            if (createMeasure) {
                /**
                 * Add reference to user if user was added to measure
                 */
                if (measure.user) {
                    const user = await onCreateMeasureUser(createMeasure.id, measure.user);
                    if (!user) {
                        userSubmit = false;
                    }
                }

                /**
                 * Add reference to qm document if qm document was added to measure
                 */
                if (measure.qmDocuments) {
                    const qmDocument = await onCreateQmDocumentsToMeasure(createMeasure.id, measure.qmDocuments);
                    if (!qmDocument) {
                        qmDocumentsSubmit = false;
                    }
                }

                /**
                 * Submit
                 */
                if (userSubmit && qmDocumentsSubmit) {
                    onOpenCloseCreateMeasure();
                    setWasSubmitted(true);
                    setSubmitError('');
                    await mutate();
                }
            }
        } catch (e) {
            throwError();
        }
    };

    /**
     * Add QM Documents to a new measure
     * @param measureId
     * @param qmTemplateDocuments
     */
    const onCreateQmDocumentsToMeasure = async (
        measureId: number | undefined,
        qmTemplateDocuments: QmDocumentTemplate[]
    ) => {
        if (measureId) {
            try {
                return await Promise.all(
                    qmTemplateDocuments.map((qmTemplateDocument) => {
                        return apiAddQmDocumentToMeasure(qmTemplateDocument.name, measureId, qmTemplateDocument.id);
                    })
                );
            } catch (e) {
                throwError('Leider ist ein Fehler aufgetreten.');
            }
        }
    };

    /**
     * Create a new measure_user reference
     * @param measureId
     * @param userIds
     */
    const onCreateMeasureUser = async (measureId: number | undefined, userIds: number[]) => {
        if (userIds && measureId) {
            try {
                return await Promise.all(
                    userIds.map((userId) => {
                        return apiAddUserToMeasure(userId, measureId);
                    })
                );
            } catch (e) {
                throwError(
                    'Leider ist bei der Erstellung ein Fehler aufgetreten. Sollte der Fehler öfter auftreten, kontaktieren Sie bitte Ihren Administrator.'
                );
                setWasSubmitted(true);
            }
        }
    };

    /**
     * Set the measure that was selected by the user
     * @param measure
     */
    const onChangeSelectedMeasure = async (measure: Measure) => {
        dispatch(setSelectedMeasure(measure));
    };

    return (
        <div className={`measure-tab`}>
            {createMeasure ? (
                <>
                    {!wasSubmitted && <LoadingView />}
                    <CreateMeasure
                        submitError={submitError}
                        onClose={onOpenCloseCreateMeasure}
                        onSubmit={onSubmitMeasure}
                    />
                </>
            ) : (
                <>
                    <MeasureList
                        onChangeSelectedMeasure={onChangeSelectedMeasure}
                        measures={measures}
                        onCreateNewMeasure={onOpenCloseCreateMeasure}
                        selectedMeasure={selectedMeasure}
                    />
                    <MeasureInformation
                        resetDataSelected={resetDataSelected}
                        mutate={mutate}
                        measure={selectedMeasure}
                    />
                </>
            )}
        </div>
    );
};
