import React, { useMemo, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { DropDown } from '../../components/DropDown/DropDown';
import { ExportPresenceModal } from '../../components/ExportPresenceModal/ExportPresenceModal';
import { Icon } from '../../components/Icon/Icon';
import { PresenceCalendar } from '../../components/PresenceCalendar/PresenceCalendar';
import { getLocaleMonth } from '../../models/Date/Date';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import './UserMeasureAttendanceManagement.css';
import { AttendanceLegend } from '../../components/AttendanceKeysInfo/AttendanceLegend';
import { useParticipants } from '../../models/Participant/Hooks';
import { useMeasures } from '../../models/Measure/Hooks';

export const UserMeasureAttendanceManagement = () => {
    const [month, setMonth] = useState<Date>(new Date());
    const [showKeys, setShowKeys] = useState<boolean>(false);
    const [showExportData, setShowExportData] = useState<boolean>(false);
    const measure = useMeasures((x) => x.selectedMeasure);
    const participants = useParticipants((x) => x.participantsWithPresences);
    const onShowKeys = () => {
        setShowKeys(!showKeys);
    };

    /**
     * Called if the user changes the month.
     * Updates the current month to the previous, current or next month depedning on the selection.
     * Current means the month of the current day.
     *
     * @param newMonth
     */
    const onChangeMonth = (newMonth: 'previous' | 'current' | 'next') => {
        const today = new Date();

        switch (newMonth) {
            case 'current':
                setMonth(new Date(today.getFullYear(), today.getMonth(), 1));
                break;
            case 'next':
                if (new Date().getMonth() === 11) {
                    setMonth(new Date(new Date().getFullYear() + 1, 0, 1));
                } else {
                    setMonth(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1));
                }

                break;
            case 'previous':
                if (new Date().getMonth() === 0) {
                    setMonth(new Date(today.getFullYear() - 1, 11, 1));
                } else {
                    setMonth(new Date(today.getFullYear(), today.getMonth() - 1, 1));
                }
        }
    };

    /**
     * Callback to show the export data modal
     */
    const onChangeShowExportData = () => {
        setShowExportData(!showExportData);
    };

    /**
     * Appends the current measueresParticipant to every participant.
     * If no measuere or participants exists, the value will be undefined.
     */
    const sortedParticipants: ParticipantWithPresence[] | undefined = useMemo(() => {
        if (!participants) return undefined;

        return [...participants].sort((a, b) => a.lastName.localeCompare(b.lastName));
    }, [participants]);

    return (
        <div className="user-measure-attendance-management">
            <div className="user-measure-attendance-management-title">
                <h5>Anwesenheit</h5>
                <Button
                    firstIcon={<Icon type={'Download'} />}
                    type={'primary'}
                    size={'medium'}
                    buttonStyle={'filled'}
                    text={'Daten exportieren'}
                    onClick={onChangeShowExportData}
                />
            </div>
            <Card className={'user-measure-attendance-management-card'}>
                <div className="user-measure-attendance-management-buttons">
                    <Button
                        onClick={onShowKeys}
                        type={'secondary'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        firstIcon={<Icon type={'Information'} />}
                        text={'Legende anzeigen'}
                    />
                    <DropDown
                        title={
                            <>
                                <div>{getLocaleMonth(month.getMonth())}</div>
                                <Icon type={'ChevronDown'} />
                            </>
                        }
                        titleClassName={'user-measure-attendance-management-title'}
                        bodyClassName={'user-measure-attendance-management-dropdown-body'}
                    >
                        <div
                            className="user-measure-attendance-management-dropdown"
                            onClick={() => onChangeMonth('next')}
                        >
                            Nächster Monat
                        </div>
                        <div
                            className="user-measure-attendance-management-dropdown"
                            onClick={() => onChangeMonth('current')}
                        >
                            Diesen Monat
                        </div>
                        <div
                            className="user-measure-attendance-management-dropdown"
                            onClick={() => onChangeMonth('previous')}
                        >
                            Letzten Monat
                        </div>
                    </DropDown>
                </div>
                {showKeys && <AttendanceLegend />}
                {sortedParticipants && <PresenceCalendar participants={sortedParticipants} month={month} />}
            </Card>
            {measure && participants && (
                <ExportPresenceModal
                    onClose={onChangeShowExportData}
                    show={showExportData}
                    participants={participants}
                    measure={measure}
                />
            )}
        </div>
    );
};
