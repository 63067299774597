import { Formik } from 'formik';
import React from 'react';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { Card } from '../Card/Card';
import { Label } from '../Label/Label';
import { ParticipantMobilityCardForm } from '../ParticipantMobilityCardForm/ParticipantMobilityCardForm';

interface ParticipantMobilityCardProps {
    participant: ParticipantWithPresence | undefined;
}

export const ParticipantMobilityCard = (props: ParticipantMobilityCardProps) => {
    const initialUserDetailsFormValues = props.participant;

    return (
        <Card key={props.participant?.id}>
            {initialUserDetailsFormValues && (
                <Formik
                    initialValues={initialUserDetailsFormValues}
                    // We need to pass a submit handler because formik expects one, but we do not use the formik submit mechanism
                    onSubmit={() => console.log('Form submit.')}
                    enableReinitialize
                >
                    {({ values, errors, handleChange, setFieldValue }) => (
                        <div className="participant-details-container">
                            <div>
                                <Label className="user-details-general-title" size={2}>
                                    MOBILITÄT
                                </Label>
                                <div className="participant-details">
                                    <div className="participant-details-form">
                                        <ParticipantMobilityCardForm
                                            errors={errors}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Card>
    );
};
