import styles from './UserMeasureUserManagementSubMenu.module.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../configuration/AppRoutes';
import { Measure } from '../../models/Measure/Types';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { AppNavigationSubMenu } from '../AppNavigationSubMenu/AppNavigationSubMenu';
import { Button } from '../Button/Button';
import { CreateNewInternshipModal } from '../CreateNewInternshipModal/CreateNewInternshipModal';
import { DropDown } from '../DropDown/DropDown';
import { Icon } from '../Icon/Icon';
import { LetterModal } from '../LetterModal/LetterModal';
import { ParticipantArchiveModal } from '../ParcitipantArchiveModal/ParticipantArchiveModal';
import { ParticipantNeedForActionModal } from '../ParticipantNeedForActionModal/ParticipantNeedForActionModal';
import { PrintReportModal } from '../PrintReportModal/PrintReportModal';
import { QmDocumentModal } from '../QmDocumentModal/QmDocumentModal';
import { useMeasures } from '../../models/Measure/Hooks';
import { useParticipants } from '../../models/Participant/Hooks';

export const UserMeasureUserManagementSubMenu = () => {
    const measure: Measure | undefined = useMeasures((x) => x.selectedMeasure);
    const participant: ParticipantWithPresence | undefined = useParticipants((x) => x.selectedParticipant);
    const [showModal, setShowModal] = useState<
        'qmDocument' | 'archive' | 'needForAction' | 'internship' | 'letter' | 'report' | undefined
    >();

    /**
     * Callback to show the qm document modal
     */
    const onShowQmDocumentModal = () => {
        if (showModal) {
            setShowModal(undefined);
        } else {
            setShowModal('qmDocument');
        }
    };

    /**
     * Callback to show the report modal in order to print a report
     */
    const onShowReportModal = () => {
        if (showModal) {
            setShowModal(undefined);
        } else {
            setShowModal('report');
        }
    };

    /**
     * Open/Close archive modal
     */
    const onShowArchiveModal = () => {
        if (showModal) {
            setShowModal(undefined);
        } else {
            setShowModal('archive');
        }
    };

    /**
     * Callback  to show the need for action modal
     */
    const onShowNeedForActionModal = () => {
        if (showModal) {
            setShowModal(undefined);
        } else {
            setShowModal('needForAction');
        }
    };

    /**
     * Callback to show the internship modal
     */
    const onShowInternshipModal = () => {
        if (showModal) {
            setShowModal(undefined);
        } else {
            setShowModal('internship');
        }
    };

    /**
     * Callback to show the letter printing modal
     */
    const onShowLetterModal = () => {
        if (showModal) {
            setShowModal(undefined);
        } else {
            setShowModal('letter');
        }
    };

    return (
        <div className="sub-menu">
            <div className="sub-menu-head">
                <Link to={AppRoutes.projectsTab(measure?.id)}>
                    <Button
                        buttonStyle={'link'}
                        text={'Zurück'}
                        firstIcon={<Icon type={'ArrowLeft'} />}
                        size={'small'}
                        type={'secondary'}
                    />
                </Link>
                <div className={styles.participantNameContainer}>
                    <h6>
                        {participant?.firstName} {participant?.lastName}
                    </h6>
                </div>
            </div>
            <div className="sub-menu-items">
                <AppNavigationSubMenu items={['Förderpläne', 'Handlungsbedarf', 'Informationen', 'Ansprechpartner']} />
                <div className="sub-menu-items-dropdown">
                    <DropDown
                        title={
                            <Button
                                type={'secondary'}
                                size={'small'}
                                buttonStyle={'filled'}
                                text={'Dokumente'}
                                secondIcon={<Icon type={'ChevronDown'} />}
                            />
                        }
                    >
                        <div onClick={onShowLetterModal}>Brief verfassen</div>
                        <div onClick={onShowQmDocumentModal}>QM-Dokumente drucken</div>
                        <div onClick={onShowReportModal}>Bericht drucken</div>
                    </DropDown>
                    <DropDown
                        title={
                            <Button
                                type={'secondary'}
                                size={'small'}
                                buttonStyle={'filled'}
                                text={'Teilnehmer verwalten'}
                                secondIcon={<Icon type={'ChevronDown'} />}
                            />
                        }
                    >
                        <div onClick={onShowNeedForActionModal}>Dokumentation</div>
                        <div onClick={onShowInternshipModal}>Praktikum buchen</div>
                        {!participant?.measuresParticipant?.inactive ? (
                            <div onClick={onShowArchiveModal}>Teilnehmer deaktivieren </div>
                        ) : null}
                    </DropDown>
                </div>
            </div>
            <>
                {!participant?.measuresParticipant?.inactive && (
                    <ParticipantArchiveModal show={showModal === 'archive'} onClose={onShowArchiveModal} />
                )}
                <ParticipantNeedForActionModal
                    show={showModal === 'needForAction'}
                    onClose={onShowNeedForActionModal}
                />
                <CreateNewInternshipModal
                    participant={participant}
                    show={showModal === 'internship'}
                    onClose={onShowInternshipModal}
                />
            </>
            <QmDocumentModal show={showModal === 'qmDocument'} onClose={onShowQmDocumentModal} />
            <LetterModal show={showModal === 'letter'} onClose={onShowLetterModal} />
            {participant && measure && showModal === 'report' && (
                <PrintReportModal
                    participant={participant}
                    show={showModal === 'report'}
                    onClose={onShowReportModal}
                    targetPath={`pdf_creation/report?participant_id=${participant.id}&measure_id=${measure.id}`}
                    filename={`Bericht_${measure.name || ''}_${participant.firstName || ''}_${
                        participant.lastName || ''
                    }.pdf`}
                />
            )}
        </div>
    );
};
