import { useState } from 'react';
import useSWR from 'swr';
import { formatDate } from '../../models/Date/Date';
import { Internship } from '../../models/Internship/Types';
import { InternshipCompany } from '../../models/InternshipCompanies/Type';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Card } from '../Card/Card';
import { Label } from '../Label/Label';
import { Icon } from '../Icon/Icon';
import { ChangeInternshipModal } from '../ChangeInternshipModal/ChangeInternshipModal';
import { useInternshipsApi } from '../../api/useInternshipsApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface ParticipantInternshipsProps {
    participant: ParticipantWithPresence;
}

export const ParticipantInternships = (props: ParticipantInternshipsProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const { data: internships, mutate: reloadInternships } = useSWR<Internship[]>([
        backendUrl,
        `participants/${props.participant.id}/internships`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: companies } = useSWR<InternshipCompany[]>([
        backendUrl,
        'internship_companies',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [selectedInternship, setSelectedInternship] = useState<Internship>();
    const { apiDeleteInternship } = useInternshipsApi();

    /**
     * Select an internship and based on that show the change modal etc
     * @param internship
     */
    const onChangeInternship = (internship?: Internship) => {
        setSelectedInternship(selectedInternship ? undefined : internship);
    };

    /**
     * Call back to delete internship
     */
    const onDeleteInternship = async (internship: Internship) => {
        try {
            await apiDeleteInternship(internship.id);
            await reloadInternships();
            showSuccessMessage('Praktikum gelöscht');
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    return (
        <Card key={props.participant.id}>
            {selectedInternship && (
                <ChangeInternshipModal
                    participant={props.participant}
                    companies={companies}
                    reloadInternship={reloadInternships}
                    onClose={onChangeInternship}
                    show={!!setSelectedInternship}
                    internship={selectedInternship}
                />
            )}
            <Label className="user-details-general-title" size={2}>
                GEBUCHTE PRAKTIKA
            </Label>
            <div className="company-internships">
                <div className="company-internships-head">
                    <Label size={2} color={'#A1A1AA'}>
                        Betrieb
                    </Label>
                    <Label size={2} color={'#A1A1AA'}>
                        Beruf
                    </Label>
                    <Label size={2} color={'#A1A1AA'}>
                        Zeitraum
                    </Label>
                    <div />
                </div>
                <div className="company-internships-body">
                    {internships?.map((internship) => {
                        return (
                            <div key={internship.id} className="company-internships-body-item">
                                <div className="company-internships-body-item-element">
                                    {companies?.find((company) => company.id === internship.internshipCompanyId)?.name}
                                </div>
                                <div className="company-internships-body-item-element">{internship.name}</div>
                                <div className="company-internships-body-item-element">
                                    {formatDate(internship.startDate)}-{formatDate(internship.endDate)}
                                </div>
                                <div className="company-internships-body-item-element delete-and-update">
                                    <Icon
                                        className={'company-internships-body-item-element-update'}
                                        type={'DocumentText'}
                                        onClick={() => onChangeInternship(internship)}
                                    />
                                    <Icon
                                        className={'company-internships-body-item-element-delete'}
                                        type={'Delete'}
                                        onClick={() => onDeleteInternship(internship)}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div></div>
            </div>
        </Card>
    );
};
