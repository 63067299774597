import { useEzApiHttpClient } from '@d4us1/ez-on-rails-react';
import { useCallback } from 'react';
import { NeedCommunity, NeedCommunityProperties } from '../models/NeedCommunity/Types';
import { Participant, ParticipantDeactivationSubmit } from '../models/Participant/Types';

/**
 * Hook that returns methods to communicate with the backends Need communities api.
 */
export const useNeedCommunitiesApi = () => {
    const { call } = useEzApiHttpClient();

    /**
     * API to get all need communities of the specified measure.
     *
     */
    const apiGetNeedCommunities = useCallback(
        async (measureId: number): Promise<NeedCommunity[]> => {
            return call<void, NeedCommunity[]>(`measures/${measureId}/need_communities/with_participants`, 'GET');
        },
        [call]
    );

    /**
     * Creates a need community by the given properties and returns the created object including the id.
     */
    const apiCreateNeedCommunity = useCallback(
        async (properties: NeedCommunityProperties) => {
            return call<NeedCommunityProperties, NeedCommunity>(`need_communities`, 'POST', properties);
        },
        [call]
    );

    /**
     * Updates the need community by the given properties and returns the updated object including.
     */
    const apiUpdateNeedCommunity = useCallback(
        async (needCommunity: NeedCommunity, properties: Partial<NeedCommunityProperties>) => {
            return call<Partial<NeedCommunityProperties>, NeedCommunity>(
                `need_communities/${needCommunity.id}`,
                'PATCH',
                properties
            );
        },
        [call]
    );

    /**
     * Deletes the need community.
     */
    const apiDeleteNeedCommunity = useCallback(
        async (needCommunity: NeedCommunity) => {
            return call<void, void>(`need_communities/${needCommunity.id}`, 'DELETE');
        },
        [call]
    );

    /**
     * Adds the participant to the specified need community.
     */
    const apiAddParticipantToNeedCommunity = useCallback(
        async (needCommunity: NeedCommunity, participant: Participant) => {
            return call<void, void>(`need_communities/${needCommunity.id}/participants/${participant.id}/add`, 'POST');
        },
        [call]
    );

    /**
     * Removes the participant from the specified need community.
     */
    const apiRemoveParticipantFromNeedCommunity = useCallback(
        async (needCommunity: NeedCommunity, participant: Participant) => {
            return call<void, void>(
                `need_communities/${needCommunity.id}/participants/${participant.id}/remove`,
                'DELETE'
            );
        },
        [call]
    );

    /**
     * Closes the need community and archives all participants from the measure.
     */
    const apiCloseNeedCommunity = useCallback(
        async (needCommunity: NeedCommunity, params: ParticipantDeactivationSubmit) => {
            return call<ParticipantDeactivationSubmit, void>(
                `need_communities/${needCommunity.id}/close`,
                'DELETE',
                params
            );
        },
        [call]
    );

    return {
        apiGetNeedCommunities: apiGetNeedCommunities,
        apiCreateNeedCommunity: apiCreateNeedCommunity,
        apiUpdateNeedCommunity: apiUpdateNeedCommunity,
        apiDeleteNeedCommunity: apiDeleteNeedCommunity,
        apiAddParticipantToNeedCommunity: apiAddParticipantToNeedCommunity,
        apiRemoveParticipantFromNeedCommunity: apiRemoveParticipantFromNeedCommunity,
        apiCloseNeedCommunity: apiCloseNeedCommunity
    };
};
