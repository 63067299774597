import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Participant } from '../../models/Participant/Types';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { ObjectList } from '../ObjectList/ObjectList';
import './ParticipantsList.css';
import { ParticipantsListItem } from '../ParticipantsListItem/ParticipantsListItem';
import { useParticipants } from '../../models/Participant/Hooks';
import { NeedCommunity } from '../../models/NeedCommunity/Types';

interface ParticipantsProps {
    onSelectParticipant: (arg: Participant) => void;
    selectedParticipant?: Participant;
    activeParticipants: Participant[];
    inactiveParticipants: Participant[];
    onCreateNewParticipant: () => void;

    // If this is given, the list will show the participants grouped by the need communities
    needCommunities?: NeedCommunity[];
}

export const ParticipantsList = (props: ParticipantsProps) => {
    const [menuSelected, setMenuSelected] = useState<string>('active');

    // The records key is the id of the needCommunity, if not need community is given, the id is 0
    const [filteredActiveParticipants, setFilteredActiveParticipants] = useState<Record<number, Participant[]>>({});

    // The records key is the id of the needCommunity, if not need community is given, the id is 0
    const [filteredInactiveParticipants, setFilteredInactiveParticipants] = useState<Record<number, Participant[]>>({});

    const participant = useParticipants((x) => x.selectedParticipant);

    const [showSearch, setShowSearch] = useState<boolean>(false);

    const onClickOpenSearch = () => {
        setShowSearch(!showSearch);
    };

    /**
     * Returns a structured record having all participants that are given by the participantsToFilterBy param.
     * The result is grouped by the need community id and its values are the participants of the need community.
     * The participantsToFilterBy are used to only show those participants, being also in this list.
     * If no need communities are given, a record having the id 0 with all entries of participantsToFilterBy will be returned.
     * Otherwise the 0 entry contains all participants being in no need community, but being in participantsToFilterBy.
     */
    const getSortedParticipants = useCallback(
        (participantsToFilterBy: Participant[]) => {
            // The results key is the id of the need commnunity, 0 for no need community
            const res: Record<number, Participant[]> = { 0: [] };

            // no need communities given => all participants should belong to group without id
            if (!props.needCommunities) {
                res[0] = participantsToFilterBy;
            } else {
                // Group participants by need communities
                props.needCommunities.forEach((needCommunity) => {
                    const participants = needCommunity.participants?.filter((participant) => {
                        return participantsToFilterBy.some(
                            (participantToFilterBy) => participantToFilterBy.id === participant.id
                        );
                    });

                    res[needCommunity.id] = participants || [];
                });

                // Add alöl participants with no need community to the 0 group
                const participantsWithNeedCommunity: Participant[] = props.needCommunities.flatMap(
                    (needCommunity) => needCommunity.participants || []
                );
                participantsToFilterBy.forEach((participant) => {
                    if (
                        !participantsWithNeedCommunity.some(
                            (participantWithNeedCommunity) => participantWithNeedCommunity.id === participant.id
                        )
                    ) {
                        res[0].push(participant);
                    }
                });
            }

            // Sort data
            Object.keys(res).forEach((key: string) => {
                const needCommunityId = parseInt(key, 10);

                res[needCommunityId] = [...res[needCommunityId]].sort((a: Participant, b: Participant) =>
                    a.lastName.localeCompare(b.lastName)
                );
            });

            return res;
        },
        [props.needCommunities]
    );

    /**
     * Sorts the active participants by its names
     */
    const sortedActiveParticipants: Record<number, Participant[]> = useMemo(() => {
        return getSortedParticipants(props.activeParticipants);
    }, [getSortedParticipants, props.activeParticipants]);

    /**
     * Sorts the active participants by its names
     */
    const sortedInactiveParticipants: Record<number, Participant[]> = useMemo(() => {
        return getSortedParticipants(props.inactiveParticipants);
    }, [getSortedParticipants, props.inactiveParticipants]);

    useEffect(() => {
        setFilteredActiveParticipants(sortedActiveParticipants);
    }, [sortedActiveParticipants]);

    useEffect(() => {
        setFilteredInactiveParticipants(sortedInactiveParticipants);
    }, [sortedInactiveParticipants]);

    /**
     * Called if the selected participant changes.
     * Calls the callback given by the props.
     */
    const onClickGetData = useCallback(
        (_index: number | string, item: Participant) => {
            props.onSelectParticipant(item);
        },
        // This is because formally the props object is referenced as "this" in the callback, hence formally old data
        // could be used. We do not use "this", hence this is ok here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onSelectParticipant]
    );

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        // create the filtered items based on the participant's input
        if (menuSelected === 'active' && value) {
            const activeParticipantsBuffer: Record<number, Participant[]> = {};

            Object.keys(sortedActiveParticipants).forEach((key: string) => {
                const needCommunityId = parseInt(key, 10);

                activeParticipantsBuffer[needCommunityId] = sortedActiveParticipants[needCommunityId].filter(
                    (item: Participant) =>
                        item.firstName.toLowerCase().includes(value?.toLowerCase()) ||
                        item.lastName.toLowerCase().includes(value?.toLowerCase())
                );
            });

            setFilteredActiveParticipants(activeParticipantsBuffer);
        } else if (value) {
            const inactiveParticipantsBuffer: Record<number, Participant[]> = {};

            Object.keys(sortedInactiveParticipants).forEach((key: string) => {
                const needCommunityId = parseInt(key, 10);

                inactiveParticipantsBuffer[needCommunityId] = sortedInactiveParticipants[needCommunityId].filter(
                    (item: Participant) =>
                        item.firstName.toLowerCase().includes(value?.toLowerCase()) ||
                        item.lastName.toLowerCase().includes(value?.toLowerCase())
                );
            });

            setFilteredInactiveParticipants(inactiveParticipantsBuffer);
        } else {
            if (menuSelected === 'active') {
                setFilteredActiveParticipants(sortedActiveParticipants);
            } else {
                setFilteredInactiveParticipants(sortedInactiveParticipants);
            }
        }
    };

    /**
     * if no item is selected, select the first item.
     * If some participant is selected, but the correct menu (inactive or active) is not selected, select it.
     */
    useEffect(() => {
        // No participant was selected => search for the first one and select it
        if (!props.selectedParticipant) {
            const filteredActiveParticipantsKeys = Object.keys(filteredActiveParticipants);

            for (const filteredActiveParticipantsKey of filteredActiveParticipantsKeys) {
                const needCommunityId = parseInt(filteredActiveParticipantsKey, 10);

                if (filteredActiveParticipants[needCommunityId].length > 0) {
                    onClickGetData(0, filteredActiveParticipants[needCommunityId][0]);
                    return;
                }
            }
        }

        // Participant already selected, select active or inactive menu depending on the participant active state
        if (props.selectedParticipant) {
            if (
                props.activeParticipants?.some(
                    (activeParticipant) => activeParticipant.id === props.selectedParticipant?.id
                )
            ) {
                setMenuSelected('active');
            } else if (
                props.inactiveParticipants?.some(
                    (inactiveParticipant) => inactiveParticipant.id === props.selectedParticipant?.id
                )
            ) {
                setMenuSelected('inactive');
            }
        }
    }, [
        filteredActiveParticipants,
        filteredInactiveParticipants,
        props.activeParticipants,
        props.inactiveParticipants,
        props.selectedParticipant,
        onClickGetData,
        participant
    ]);

    /**
     * Returns the number of active participants.
     */
    const activeParticipantsSum = useMemo(() => {
        let res = 0;

        Object.values(filteredActiveParticipants).forEach((participants) => {
            res += participants.length;
        });

        return res;
    }, [filteredActiveParticipants]);

    /**
     * Returns the number of active participants.
     */
    const inactiveParticipantsSum = useMemo(() => {
        let res = 0;

        Object.values(filteredInactiveParticipants).forEach((participants) => {
            res += participants.length;
        });

        return res;
    }, [filteredInactiveParticipants]);

    /**
     * Returns the label for the specified needCommunity.
     * If no need communities are provided, the label is null, hence it is not rendered.
     */
    const renderNeedCommunityLabelFor = useCallback(
        (needCommunityId: number) => {
            if (!props.needCommunities) {
                return null;
            }

            return (
                <div className={'participants-list-need-community-label'}>
                    {needCommunityId === 0
                        ? 'Keine BG'
                        : props.needCommunities?.find((needCommunity) => needCommunity.id === needCommunityId)?.name}
                </div>
            );
        },
        [props.needCommunities]
    );

    /**
     * Renders the body of the list for the specified participants.
     *
     * @param filteredParticipants
     */
    const renderParticipantsList = useCallback(
        (filteredParticipants: Record<number, Participant[]>) => {
            return (
                <div className="participants-list">
                    <div className={'seperator'} />
                    {Object.keys(filteredParticipants).map((key) => {
                        const needCommunityId = parseInt(key, 10);

                        const res: ReactNode[] = [renderNeedCommunityLabelFor(needCommunityId)];

                        // Add participants list
                        filteredParticipants[needCommunityId].forEach((participant, index) => {
                            res.push(
                                <ObjectList.Item
                                    key={participant.id}
                                    onClick={onClickGetData}
                                    value={{ index, item: participant }}
                                    selected={props.selectedParticipant?.id === participant.id}
                                >
                                    <ParticipantsListItem participant={participant} />
                                </ObjectList.Item>
                            );
                        });

                        return res;
                    })}
                </div>
            );
        },
        [onClickGetData, props.selectedParticipant?.id, renderNeedCommunityLabelFor]
    );

    return (
        <ObjectList>
            <ObjectList.Head>
                <div className="participants-list-head-container">
                    <div className="participants-list-head">
                        <div className="p2-medium">Teilnehmer</div>
                        <div className="participants-list-head-search">
                            <Button
                                type={'secondary'}
                                buttonStyle={'link'}
                                size={'small'}
                                firstIcon={<Icon type={'Search'} />}
                                onClick={onClickOpenSearch}
                            />
                            <Button
                                type={'primary'}
                                size={'small'}
                                buttonStyle={'filled'}
                                firstIcon={<Icon type={'Plus'} />}
                                onClick={props.onCreateNewParticipant}
                            />
                        </div>
                    </div>
                    {showSearch && (
                        <Input
                            icon={<Icon type={'Search'} />}
                            placeholder={'Mitarbeiter suchen'}
                            onChange={onChangeSearch}
                        />
                    )}
                </div>
                <div className="participants-list-navigation">
                    <div
                        className={`participants-list-navigation-item label-2 ${menuSelected === 'active' && 'active'}`}
                        onClick={() => setMenuSelected('active')}
                    >
                        AKTIV ({activeParticipantsSum})
                    </div>
                    <div
                        className={`participants-list-navigation-item label-2 ${
                            menuSelected === 'inactive' && 'active'
                        }`}
                        onClick={() => setMenuSelected('inactive')}
                    >
                        ARCHIVIERT ({inactiveParticipantsSum})
                    </div>
                </div>
            </ObjectList.Head>
            {menuSelected === 'active' && (
                <ObjectList.Body>{renderParticipantsList(filteredActiveParticipants)}</ObjectList.Body>
            )}
            {menuSelected === 'inactive' && (
                <ObjectList.Body>{renderParticipantsList(filteredInactiveParticipants)}</ObjectList.Body>
            )}
        </ObjectList>
    );
};
