import { StandardView } from '../../components/StandardView/StandardView';
import { NeedCommunitiesList } from '../../components/NeedCommunitiesList/NeedCommunitiesList';
import { NeedCommunityForm } from '../../components/NeedCommunityForm/NeedCommunityForm';

export const NeedCommunitiesPage = () => {
    return (
        <StandardView>
            <StandardView.Left>
                <NeedCommunitiesList />
            </StandardView.Left>
            <StandardView.Right>
                <NeedCommunityForm />
            </StandardView.Right>
        </StandardView>
    );
};
