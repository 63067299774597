import { useAppSelector } from '../../hooks';
import { ParticipantState } from './State';

/**
 * Returns a value from the porticipants state by using the specified provider that takes the users state as parameter.
 *
 * @param mapping
 */
export const useParticipants = <T>(mapping: (state: ParticipantState) => T) => {
    return useAppSelector<T>((x) => mapping(x.participant));
};
