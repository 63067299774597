import { Formik } from 'formik';
import React from 'react';
import {
    NeedForActionSubmit,
    NeedForActionSuggestion,
    NeedForActionSuggestionTypes
} from '../../models/NeedForAction/Type';
import { useUser } from '../../models/User/State';
import { CreateNeedForActionForm } from '../CreateNeedForActionForm/CreateNeedForActionForm';
import './CreateNeedForAction.css';
import { useParticipants } from '../../models/Participant/Hooks';
import { useMeasures } from '../../models/Measure/Hooks';

interface CreateNeedForActionProps {
    needForAction: {
        action: NeedForActionSuggestionTypes;
        suggestion: NeedForActionSuggestion[] | undefined;
    };
    onGoBack: () => void;
    multipleAnswers?: boolean;
    onClose: () => void;
}

export const CreateNeedForAction = (props: CreateNeedForActionProps) => {
    const participant = useParticipants((x) => x.selectedParticipant);
    const measure = useMeasures((x) => x.selectedMeasure);
    const currentUser = useUser((x) => x.currentUser);

    if (!participant || !measure) return null;

    const initialFormValues: NeedForActionSubmit = {
        category: props.needForAction.suggestion ? props.needForAction.suggestion[0].category : undefined,
        name: props.needForAction?.action?.needForActionType,
        needForActionType: props.needForAction?.action?.needForActionType,
        options: [],
        participantId: participant.id,
        measureId: measure.id,
        priority: 'information',
        comment: {
            text: '',
            date: new Date(),
            needForActionId: undefined,
            user: {
                id: currentUser?.id
            }
        },
        newComment: undefined,
        estimate: undefined
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={() => {
                console.log();
            }}
        >
            {({ values, handleChange, setFieldValue }) => (
                <CreateNeedForActionForm
                    onClose={props.onClose}
                    multipleAnswers={props.multipleAnswers}
                    initialValues={initialFormValues}
                    onGoBack={props.onGoBack}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    values={values}
                    needForAction={props.needForAction}
                />
            )}
        </Formik>
    );
};
