import { Formik } from 'formik';
import * as Yup from 'yup';
import { ParticipantSubmit } from '../../models/Participant/Types';
import { QmDocumentDocx } from '../../models/QmDocument/Types';
import { CreateParticipantForm } from '../CreateParticipantForm/CreateParticipantForm';
import { ObjectShape } from 'yup';
import { useMeasures } from '../../models/Measure/Hooks';

interface CreateParticipantProps {
    onClose: () => void;
    onSetDocuments: (arg: QmDocumentDocx[]) => void;
}

export const CreateParticipant = (props: CreateParticipantProps) => {
    const currentMeasure = useMeasures((x) => x.selectedMeasure);

    const initialFormValues: ParticipantSubmit = {
        birthday: new Date(1980),
        birthplace: '',
        city: '',
        comment: '',
        name: '',
        legalSphere: undefined,
        contact: '',
        stemGroup: '',
        supporter: '',
        clientNumber: '',
        employee: '',
        email: '',
        firstName: '',
        gender: 'diverse',
        highestGraduation: 'left_school_without_graduation_diploma',
        highestProfessionalGraduation: 'no_high_graduation',
        entranceDate: currentMeasure?.startAt ? new Date(currentMeasure?.startAt) : new Date(),
        lastName: '',
        mobilePhone: '',
        nationality: '',
        participantClientId: 0,
        phone: '',
        plannedLeaveDate: new Date(),
        streetAndNumber: '',
        zipCode: '',
        clientLegalSphere: undefined,
        clientCustomerId: '',
        presenceType: '',
        highestGraduationOther: '',
        highestProfessionalGraduationOther: ''
    };

    const validationSchema = (object: ParticipantSubmit) => {
        const returnObject: ObjectShape = {};
        Object.keys(object).forEach((key) => {
            if (key === 'mobilePhone' || key === 'phone') {
                returnObject[key] = Yup.string()
                    .required('Dieses Feld muss ausgefüllt sein.')
                    .matches(phoneRegx, 'Dies scheint keine gültige Telefonnummer zu sein');
            } else {
                returnObject[key] = Yup.string().required('Dieses Feld muss ausgefüllt sein.');
            }
        });
        return returnObject;
    };

    const phoneRegx = /[0-9]/;
    const CreateParticipantValidationScheme = Yup.object().shape(validationSchema(initialFormValues)).defined();

    return (
        <Formik
            initialValues={initialFormValues}
            // We need to pass a handler here because formik expects one, but we do not want to use the formik submit mechanism
            onSubmit={() => console.log('Form submit.')}
            validateOnMount={false}
            enableReinitialize={false}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={CreateParticipantValidationScheme}
        >
            {({ values, errors, handleChange, handleSubmit, setFieldValue, touched, validateForm, setTouched }) => (
                <CreateParticipantForm
                    onSetDocuments={props.onSetDocuments}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    validateForm={validateForm}
                    setTouched={setTouched}
                    handleSubmit={handleSubmit}
                    onCloseSidebar={props.onClose}
                />
            )}
        </Formik>
    );
};
