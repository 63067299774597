import useSWR from 'swr';
import { Course } from '../../models/Course/Type';
import { CheckList } from '../CheckList/CheckList';
import React, { useState } from 'react';
import { CourseListItem } from '../CourseListItem/CourseListItem';
import { Modal } from '../Modal/Modal';
import styles from './CoursesPrintModal.module.css';
import { DownloadButton } from '../DownloadButton/DownloadButton';
import { Icon } from '../Icon/Icon';
import { Button } from '../Button/Button';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useMeasures } from '../../models/Measure/Hooks';

/**
 * Props for the CoursesPrintModal component.
 */
interface CoursesPrintModalProps {
    // Called if the user wants to close the modal
    onClose: () => void;
}

/**
 * Shows a modal with the ability to select courses and print a pdf of the selcted courses, or print a pdf
 * for all courses.
 *
 * @constructor
 */
export const CoursesPrintModal = (props: CoursesPrintModalProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const currentMeasure = useMeasures((x) => x.selectedMeasure);
    const { data: courses } = useSWR<Course[]>([
        backendUrl,
        `measures/${currentMeasure?.id}/courses`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const [selectedCourseIds, setSelectedCourseIds] = useState<number[]>([]);

    if (!authInfo) return null;
    if (!currentMeasure) return null;

    return (
        <Modal
            show={true}
            header={'Qualifizierungsangebote drucken'}
            footer={
                <>
                    <Button
                        buttonStyle={'outline'}
                        size={'medium'}
                        type={'secondary'}
                        text={'Abbrechen'}
                        onClick={props.onClose}
                    />

                    <DownloadButton
                        targetPath={`pdf_creation/courses?measure_id=${
                            currentMeasure.id
                        }&course_ids=[${selectedCourseIds.join(',')}
               ]`}
                        filename={`Qualifizierungsangebote_${currentMeasure.name || ''}.pdf`}
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'filled'}
                        text={'Download'}
                        firstIcon={<Icon type={'Download'} />}
                    />
                </>
            }
        >
            <CheckList
                items={
                    courses?.map((course: Course) => {
                        return {
                            identifier: course.id,
                            content: <CourseListItem course={course} />
                        };
                    }) || []
                }
                selectedItems={selectedCourseIds}
                onChange={setSelectedCourseIds}
                itemsContainerClassName={styles.coursesList}
            />
        </Modal>
    );
};
