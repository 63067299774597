import { Measure } from '../../models/Measure/Types';
import { Label } from '../Label/Label';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { SingleParticipantInformation } from '../SingleParticipantInformation/SingleParticipantInformation';
import './ParticipantsManagement.css';
import { Field } from 'formik';
import { useParticipants } from '../../models/Participant/Hooks';
import { useMemo } from 'react';

interface ParticipantsManagementProps {
    measure: Measure;

    // Are checkboxes for selecting participants supposed to be shown
    areCheckboxesEnabled?: boolean;
}

export const ParticipantsManagement = (props: ParticipantsManagementProps) => {
    const participants = useParticipants((x) => x.participantsWithPresences);

    /**
     * Sorts the participants by name.
     */
    const sortedParticipants = useMemo(() => {
        if (!participants) return null;

        return [...participants].sort((a, b) => a.lastName.localeCompare(b.lastName));
    }, [participants]);

    return (
        <div>
            <div style={{ marginBottom: '20px' }} className="participants-management-table">
                <Label size={2}>Name</Label>
                <Label size={2}>Geburtsdatum</Label>
                <Label size={2}>Kundennummer</Label>
                <Label size={2}>Status</Label>
                <div />
            </div>
            {sortedParticipants ? (
                sortedParticipants.map((participant) => {
                    return props.areCheckboxesEnabled ? (
                        <label key={participant.id} className={'participant-checkbox-label'}>
                            {/* 'value' must be converted to string because numbers aren't handled correctly due to dom limitations */}
                            <Field type={'checkbox'} name={'participantsIds'} value={participant.id.toString()} />
                            <SingleParticipantInformation key={participant.id} participant={participant} />
                        </label>
                    ) : (
                        <SingleParticipantInformation
                            key={participant.id}
                            participant={participant}
                            measure={props.measure}
                            isRemoveIconEnabled={true}
                        />
                    );
                })
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};
