import { useMeasures } from '../models/Measure/Hooks';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks';
import { setNeedCommunities, setSelectedNeedCommunity } from '../models/NeedCommunity/Slice';
import { useNeedCommunitiesApi } from '../api/useNeedCommunitiesApi';
import {
    setActiveParticipants,
    setInactiveParticipants,
    setParticipantsWithPresences,
    setSelectedParticipant
} from '../models/Participant/Slice';
import { useMeasuresApi } from '../api/useMeasuresApi';

/**
 * State manager that reacts on changes of the current selected measure.
 * Fetches related information of the measure.
 *
 * @constructor
 */
export const MeasureStateManager = () => {
    const selectedMeasure = useMeasures((x) => x.selectedMeasure);
    const [selectedMeasureId, setSelectedMeasureId] = useState<number | undefined>(undefined);
    const dispatch = useAppDispatch();
    const { apiGetNeedCommunities } = useNeedCommunitiesApi();
    const {
        apiGetActiveParticipantsInMeasure,
        apiGetInactiveParticipantsInMeasure,
        apiGetParticipantsWithPresencesInMeasure
    } = useMeasuresApi();

    /**
     * Called if the selercted measure was changed.
     * If the id differs from the current selectedMeasureId, it will be updated.
     * This makes it possible to call actions only if the measure was changed, and not on every uodate
     * of the measure itself.
     */
    useEffect(() => {
        if (selectedMeasureId !== selectedMeasure?.id) {
            setSelectedMeasureId(selectedMeasure?.id);
        }
    }, [selectedMeasure, selectedMeasureId]);

    /**
     * Called if the current selected measure changes.
     * Fetches the need communities of the measure.
     */
    useEffect(() => {
        if (!selectedMeasureId) {
            dispatch(setSelectedNeedCommunity(null));
            dispatch(setNeedCommunities(null));
        } else {
            (async () => {
                const needCommunities = await apiGetNeedCommunities(selectedMeasureId);
                dispatch(setNeedCommunities(needCommunities));
            })();
        }
    }, [apiGetNeedCommunities, dispatch, selectedMeasureId]);

    /**
     * Called if the current selected measure changes.
     * Fetches all active and inactive participants of the measure from the backend and sets it to the state.
     */
    useEffect(() => {
        if (!selectedMeasureId) {
            dispatch(setSelectedParticipant(undefined));
            dispatch(setActiveParticipants(null));
            dispatch(setInactiveParticipants(null));
            dispatch(setParticipantsWithPresences(null));
        } else {
            (async () => {
                const activeParticipants = await apiGetActiveParticipantsInMeasure(selectedMeasureId);
                dispatch(setActiveParticipants(activeParticipants));

                const inactiveParticipants = await apiGetInactiveParticipantsInMeasure(selectedMeasureId);
                dispatch(setInactiveParticipants(inactiveParticipants));

                const participantWithPresences = await apiGetParticipantsWithPresencesInMeasure(selectedMeasureId);
                dispatch(setParticipantsWithPresences(participantWithPresences));

                console.log('participantWithPresences', participantWithPresences);
            })();
        }
    }, [
        apiGetActiveParticipantsInMeasure,
        apiGetInactiveParticipantsInMeasure,
        apiGetParticipantsWithPresencesInMeasure,
        dispatch,
        selectedMeasureId
    ]);

    return null;
};
