import { Formik } from 'formik';
import React from 'react';
import avatar from '../../assets/img/user.png';
import { platformUrl } from '../../helpers';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { ActiveStorageDropzone } from '../ActiveStorageDropzone/ActiveStorageDropzone';
import { Card } from '../Card/Card';
import { Label } from '../Label/Label';
import { ParticipantDetailsList } from '../ParticipantDetailsList/ParticipantDetailsList';
import './ParticipantDetailsCard.css';
import { Icon } from '../Icon/Icon';
import { useParticipantsApi } from '../../api/useParticipantsApi';
import { useAppDispatch } from '../../hooks';
import { participantUpdated } from '../../models/Participant/Slice';

interface ParticipantDetailsCardProps {
    participant: ParticipantWithPresence;
}

export const ParticipantDetailsCard = (props: ParticipantDetailsCardProps) => {
    const { apiUpdateParticipant } = useParticipantsApi();
    const dispatch = useAppDispatch();

    /**
     * Change the participant's avatar
     * @param avatarSignedId
     */
    const onChangeAvatar = async (avatarSignedId: string | undefined) => {
        try {
            // update avatar with new one
            if (props.participant && avatarSignedId) {
                const updatedParticipant = await apiUpdateParticipant(props.participant?.id, {
                    ...props.participant,
                    ...{
                        avatar: avatarSignedId,
                        avatarUrl: null
                    }
                });

                dispatch(participantUpdated(updatedParticipant));

                // delete avatar
            } else if (props.participant) {
                const updatedParticipant = await apiUpdateParticipant(props.participant?.id, {
                    ...props.participant,
                    ...{
                        avatar: null,
                        avatarUrl: null
                    }
                });

                dispatch(participantUpdated(updatedParticipant));
            }

            showSuccessMessage('Bild geändert');
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    /**
     * Delete a participant's avatar
     * @param event
     */
    const onRemoveAvatar = async (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            if (props.participant) {
                const updatedParticipant = await apiUpdateParticipant(props.participant?.id, { avatar: null });

                dispatch(participantUpdated(updatedParticipant));

                showSuccessMessage('Bild entfernt');
            }
        } catch (e) {
            throwError();
            console.log(e);
        }
    };

    const initialUserDetailsFormValues: ParticipantWithPresence | undefined = props.participant;

    return (
        <Card key={props.participant?.id}>
            {initialUserDetailsFormValues && (
                <Formik
                    initialValues={initialUserDetailsFormValues}
                    // We need to pass a handler here because formik expects one, but we do not want to use the formik submit mechanism
                    onSubmit={() => console.log('Form submit.')}
                    enableReinitialize
                >
                    {({ values, errors, handleChange, setFieldValue }) => (
                        <div className="participant-details-container">
                            <div>
                                <Label className="user-details-general-title" size={2}>
                                    ALLGEMEINE INFORMATIONEN
                                </Label>
                                <div className="participant-details">
                                    <div className="participant-details-form">
                                        <ParticipantDetailsList
                                            values={values}
                                            errors={errors}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            participant={props.participant}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="participant-details-card-avatar">
                                <ActiveStorageDropzone
                                    className={'qm-document-template-form-upload'}
                                    overwriteOldFile={true}
                                    accept={{ 'image/*': [] }}
                                    onChange={(files) => onChangeAvatar(files.length > 0 ? files[0].signedId : '')}
                                    attributeName="document"
                                    textDropzone={'Bild ändern'}
                                    files={values.avatar ? [{ signedId: values.avatar }] : []}
                                    multiple={false}
                                    maxFiles={1}
                                    onMaxFilesError={() => alert('Es ist nur eine Datei erlaubt')}
                                    maxSize={10485760}
                                    onMaxSizeError={() => alert('Die Datei ist zu groß. Sie darf max. 10MB groß sein.')}
                                >
                                    <div className="user-details-general-picture">
                                        <img
                                            alt={'user profile'}
                                            src={
                                                props.participant?.avatarUrl
                                                    ? platformUrl(props.participant.avatarUrl)
                                                    : avatar
                                            }
                                        />
                                        <div className={'user-details-general-picture-remove'} onClick={onRemoveAvatar}>
                                            <Icon type={'Delete'} className="remove-picture" />
                                        </div>
                                        <div className="user-details-general-picture-upload">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="32"
                                                height="32"
                                                fill="currentColor"
                                                className="bi bi-cloud-arrow-up"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                                                />
                                                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                            </svg>
                                        </div>
                                    </div>
                                </ActiveStorageDropzone>
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Card>
    );
};
