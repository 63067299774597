import { useAppSelector } from '../../hooks';
import { NeedCommunitiesState } from './State';

/**
 * Returns a value from the measure state by using the specified provider that takes the users state as parameter.
 *
 * @param mapping
 */
export const useNeedCommunities = <T>(mapping: (state: NeedCommunitiesState) => T) => {
    return useAppSelector<T>((x) => mapping(x.needCommunity));
};
