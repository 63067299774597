import { Modal } from '../Modal/Modal';
import { ActivityIndicator } from '../ActivityIndicator/ActivityIndicator';
import React, { useCallback, useRef, useState } from 'react';
import { throwError } from '../../models/Toasts/Toasts';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { useAppDispatch } from '../../hooks';
import { measuresParticipantUpdated } from '../../models/Participant/Slice';
import { Textarea } from '../Textarea/Textarea';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useMeasuresApi } from '../../api/useMeasuresApi';

interface PrintReportModalProps {
    onClose: () => void;
    show: boolean;

    // The relative target path (from the api url) to the target get request to download the file
    targetPath: string;

    // The filename of the resulting downloaded file
    filename: string;

    participant: ParticipantWithPresence;
}

export const PrintReportModal = (props: PrintReportModalProps) => {
    const downloadLinkHack = useRef<HTMLAnchorElement>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [summary, setSummary] = useState<string | undefined>(props.participant.measuresParticipant?.summary);
    const dispatch = useAppDispatch();
    const { backendUrl, apiVersion, authInfo } = useEzOnRails();
    const { apiUpdateMeasureParticipant } = useMeasuresApi();

    /**
     * Copied from EzOnRails-React to create the Url to the api.
     * This should be removed if the current targeting EzOnRails-React version includes this.
     *
     * @param path
     */
    const toApiUrl = useCallback(
        (path: string): string => {
            if (path.startsWith('/')) {
                path = path.substr(1);
            }

            return `${backendUrl}/api/${path}`;
        },
        [backendUrl]
    );

    /**
     * Copied from EzOnRails-React to create a default http header having the auth info provided.
     * This should be removed if the current targeting EzOnRails-React version includes this.
     */
    const httpHeader = useCallback(() => {
        const header: HeadersInit = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'api-version': apiVersion || ''
        };

        if (!authInfo) {
            return header;
        }

        header['uid'] = authInfo.uid;
        header['client'] = authInfo.client;
        header['expiry'] = authInfo.expiry;
        header['token-type'] = authInfo.tokenType;
        header['access-token'] = authInfo.accessToken;

        return header;
    }, [apiVersion, authInfo]);

    /**
     * Starts the download defined by the targetPath in the props, by first fetching the
     * blob from the backend and attaching the result to the invisible anchor tag and automaticly
     * clicking those tag.
     * This is needed, because it is very important to understand that in the 21. century...
     * HUEHUEHUEHUEHUEHUEHUEHUEHUEHUEHUEHUEHUE...
     */
    const downloadHack = useCallback(async () => {
        if (!downloadLinkHack || !downloadLinkHack.current) {
            return;
        }

        try {
            const response = await fetch(toApiUrl(props.targetPath), {
                headers: httpHeader()
            });

            const blob = await response.blob();
            const blobHref = window.URL.createObjectURL(blob);

            downloadLinkHack.current.download = props.filename;
            downloadLinkHack.current.href = blobHref;
            downloadLinkHack.current.click();
        } catch (e) {
            throwError();
            console.log(e);
        }
    }, [httpHeader, props.filename, props.targetPath, toApiUrl]);

    /**
     * onChange to get the input field information
     * @param event
     */
    const onChangeSummary = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSummary(event.currentTarget.value);
    };

    /**
     * Create the report that should be downloaded
     */
    const onCreateReport = async () => {
        setIsProcessing(true);
        // if the summary has changed, update it before printing the report pdf
        if (summary && summary !== props.participant.measuresParticipant?.summary) {
            try {
                const updatedMeasureParticipant = await apiUpdateMeasureParticipant(
                    props.participant.measuresParticipant?.id,
                    { ...props.participant.measuresParticipant, ...{ summary: summary } }
                );

                dispatch(measuresParticipantUpdated(updatedMeasureParticipant));
            } catch (e) {
                throwError('Zusammenfassung konnte nicht aktualisiert werden.');
                console.log(e);
            }
        }

        await downloadHack();
        setIsProcessing(false);
        props.onClose();
    };

    return (
        <Modal
            show={props.show}
            header={'Bericht erstellen'}
            buttons={{
                primary: { text: 'Bericht erstellen', onClick: onCreateReport },
                secondary: { text: 'Abbrechen', onClick: props.onClose }
            }}
        >
            {isProcessing ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <ActivityIndicator />
                </div>
            ) : (
                <Textarea
                    label={'Zusammenfassung'}
                    className={'participant-archived-information-form-textarea'}
                    rows={7}
                    value={summary}
                    onChange={onChangeSummary}
                />
            )}
            <a href={'/#'} className="download-button-link" ref={downloadLinkHack}>
                Download link
            </a>
        </Modal>
    );
};
