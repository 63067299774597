import React, { Fragment, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { PresenceCalendarAttendance } from '../../pages/PresenceCalendarAttendance/PresenceCalendarAttendance';
import { Attendance } from '../Attendance/Attendance';
import { Loading } from '../Loading/Loading';
import { PresenceCalendarChangeModal } from '../PresenceCalendarChangeModal/PresenceCalendarChangeModal';
import './PresenceCalendar.css';
import { daysOfMonthCount, isToday } from '../../utils/DateUtils';

/**
 * Props for the PresenceCalendar component.
 */
interface PresenceCalendarProps {
    // Holds the month the calendar should show, since this can be in the next year, it is given as date object
    // holding some day in the target month
    month: Date;

    // The participants that presences should be shown
    participants?: ParticipantWithPresence[] | undefined;
}

/**
 * Shows a presence calendar for all participants given by the props.
 *
 * @param props
 * @constructor
 */
export const PresenceCalendar = (props: PresenceCalendarProps) => {
    const calendarBodyRef = useRef<HTMLDivElement>(null);
    const [calendarWidth, setCalendarWidth] = useState<number>();
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [attendance, setAttendance] = useState<
        | {
              event: React.MouseEvent<HTMLDivElement>;
              id: number | undefined;
              date: Date;
              participant: ParticipantWithPresence;
          }
        | undefined
    >();

    /**
     * Returns a variable that holds the first day of the selected month.
     */
    const firstDayOfMonth = useMemo(() => {
        const result = new Date(props.month);
        result.setDate(1);
        result.setHours(0);
        result.setMinutes(0);
        result.setSeconds(0);
        return result;
    }, [props.month]);

    /**
     * Returns a variable that holds the first day of the selected month.
     */
    const lastDayOfMonth = useMemo(() => {
        const result = new Date(props.month);
        result.setMonth(result.getMonth() + 1);
        result.setDate(0);
        result.setHours(0);
        result.setMinutes(0);
        result.setSeconds(0);
        return result;
    }, [props.month]);

    /**
     * Callback if some attendacne was clicked.
     * Shows the attendance options to set the attendance for the specified date.
     *
     * @param event
     * @param id
     * @param date
     * @param participant
     */
    const onClickAttendance = useCallback(
        (
            event: React.MouseEvent<HTMLDivElement>,
            id: number | undefined,
            date: Date,
            participant: ParticipantWithPresence
        ) => {
            if (!showOptions) {
                const valueDate = date;
                valueDate.setHours(10);

                setAttendance({ event: event, id: id, date: valueDate, participant: participant });
            } else {
                setAttendance(undefined);
            }

            setShowOptions(!showOptions);
        },
        [showOptions]
    );

    /**
     * Show specific presence of a participant for today.
     *
     * @param participant
     */
    const renderParticipantPresenceToday = useCallback(
        (participant: ParticipantWithPresence) => {
            const todayPresence = participant.presences?.find((presence) => isToday(presence.presentAt));

            return (
                <div
                    className={`presence-calendar-attendance today`}
                    onClick={(event) => onClickAttendance(event, todayPresence?.id, new Date(), participant)}
                >
                    <Attendance category={todayPresence?.category} />
                </div>
            );
        },
        [onClickAttendance]
    );

    /**
     * return all participants and their absences/presences and, therefore, create the absence "table"
     *
     * @param participant
     */
    const renderParticipantPresences = useCallback(
        (participant: ParticipantWithPresence) => {
            if (!participant) return null;

            return [...Array(daysOfMonthCount(props.month))].map((_day, index) => {
                return (
                    <PresenceCalendarAttendance
                        key={index}
                        month={props.month}
                        onClick={onClickAttendance}
                        participant={participant}
                        dayIndex={index + 1}
                    />
                );
            });
        },
        [onClickAttendance, props.month]
    );

    /**
     * Render the monthly dates if the month is changed
     */
    const headerDaysComponents = useMemo(() => {
        // used to iterate through the days from 0 to end of month
        // this makes it possible to identify weekend days
        const currentDay = new Date(props.month.getFullYear(), props.month.getMonth(), 1);

        return [...Array(daysOfMonthCount(props.month))].map((number, index) => {
            currentDay.setDate(index + 1);
            if (currentDay.getDay() === 6 || currentDay.getDay() === 0) {
                return (
                    <div key={index} className="absence-calendar-weekend">
                        {index + 1}.
                    </div>
                );
            } else {
                return (
                    <div key={index} className="absence-calendar-week">
                        {index + 1}.
                    </div>
                );
            }
        });
    }, [props.month]);

    /**
     * Render the presences of all participants in the props.
     * This is the "body" of the calendar.
     */
    const participantsCalendarComponent = useMemo(() => {
        return props.participants
            ?.filter((participant) => {
                // filter participants being inactive in the selected month
                // This should be the case if the participant got deactivated in the month before or earlier.
                // If the participant was deactivated in the current month, he should be visisble
                if (participant.measuresParticipant.inactiveDate) {
                    const lastDayOfInactiveMonth = new Date(participant.measuresParticipant.inactiveDate);
                    lastDayOfInactiveMonth.setMonth(lastDayOfInactiveMonth.getMonth() + 1);
                    lastDayOfInactiveMonth.setDate(0); // this should create the last day of the previous months
                    lastDayOfInactiveMonth.setHours(0);
                    lastDayOfInactiveMonth.setMinutes(0);
                    lastDayOfInactiveMonth.setSeconds(0);

                    if (firstDayOfMonth > lastDayOfInactiveMonth) {
                        return false;
                    }
                }

                // filter participants being not yet in the measure in the selected month
                if (participant.measuresParticipant.entranceDate) {
                    const entranceDate = new Date(participant.measuresParticipant.entranceDate);
                    entranceDate.setHours(0);
                    entranceDate.setMinutes(0);
                    entranceDate.setSeconds(0);

                    if (entranceDate > lastDayOfMonth) {
                        return false;
                    }
                }

                return true;
            })
            .map((participant) => {
                return (
                    <Fragment key={participant.id}>
                        <div className="absence-calendar-name-wrapper">
                            <div className={'absence-calendar-name-full-name'}>
                                {participant.lastName}, {participant.firstName}
                            </div>
                        </div>
                        {renderParticipantPresenceToday(participant)}
                        <div className="absence-calendar-days">{renderParticipantPresences(participant)}</div>
                    </Fragment>
                );
            });
    }, [
        firstDayOfMonth,
        lastDayOfMonth,
        props.participants,
        renderParticipantPresenceToday,
        renderParticipantPresences
    ]);

    const calculateCalendarWidth = () => {
        setCalendarWidth(calendarBodyRef.current?.scrollWidth);
    };

    useLayoutEffect(() => {
        document.addEventListener('resize', calculateCalendarWidth);

        return () => document.removeEventListener('resize', calculateCalendarWidth);
    });

    return (
        <div className={'absence-calendar-container-wrapper'}>
            <div
                className={'absence-calendar-container'}
                style={{ minWidth: calendarWidth || calendarBodyRef.current?.scrollWidth }}
            >
                <div className="absence-calendar sticky-header">
                    <div />
                    <div className="absence-calendar-today">heute</div>
                    <div className="absence-calendar-days-wrapper">
                        <div className="absence-calendar-days">{headerDaysComponents}</div>
                    </div>
                </div>
                <div className="absence-calendar-body" ref={calendarBodyRef}>
                    {!props.participants ? (
                        <Loading wrapper={'absence-calendar-days-loading'} repeat={5} />
                    ) : (
                        participantsCalendarComponent
                    )}
                </div>
                <PresenceCalendarChangeModal
                    attendanceInformation={attendance}
                    setShowOptions={setShowOptions}
                    showOptions={showOptions}
                />
            </div>
        </div>
    );
};
