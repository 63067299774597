import { Participant } from '../../models/Participant/Types';
import Select, { SingleValue } from 'react-select';
import { ParticipantsListItem } from '../ParticipantsListItem/ParticipantsListItem';
import styles from './SelectableParticipantsList.module.css';

/**
 * Props for the SelectableParticipantsList component.
 */
interface SelectableParticipantsListProps {
    // The participants the user can select from to add in to the list
    selectableParticipants: Participant[];

    // The current participats in the list
    participants: Participant[];

    // Called if the user adds an participant to the list
    onAddParticipant: (participant: Participant) => void;

    // Called if the user removes an participant from the list
    onRemoveParticipant: (participant: Participant) => void;

    // If this is given, it is not possible to add or remove participants to the list
    readOnly?: boolean;
}

/**
 * Shows a list of participants and a dropdown to add participants to the list.
 * Also enables the removal of participants from the list.
 * The component is stateless, hence the add and remove functionality must be handled outside.
 *
 * @param props
 * @constructor
 */
export const SelectableParticipantsList = (props: SelectableParticipantsListProps) => {
    /**
     * Called if the user clicks the button to remove a participant from the list.
     *
     * @param participant
     */
    const onClickRemoveParticipant = (participant: Participant) => {
        props.onRemoveParticipant(participant);
    };

    /**
     * Called if the user selected a participant to add.
     * Calls the callback to add the participant.
     *
     * @param participant
     */
    const onSelectAddParticipant = (newValue: SingleValue<{ value: number; label: string }>) => {
        const participant = props.selectableParticipants.find((participant) => participant.id === newValue?.value);
        if (!participant) return;

        props.onAddParticipant(participant);
    };

    return (
        <div className={styles.container}>
            <div className={styles.headContainer}>
                <div className="p2-medium">Teilnehmer</div>
                {!props.readOnly && (
                    <Select
                        value={null}
                        options={props.selectableParticipants.map((participant) => {
                            return {
                                value: participant.id,
                                label: `${participant.firstName} ${participant.lastName}`
                            };
                        })}
                        onChange={onSelectAddParticipant}
                        placeholder={'Teilnehmer hinzufügen'}
                    />
                )}
            </div>
            <div className={styles.bodyContainer}>
                {props.participants?.map((participant) => {
                    return (
                        <ParticipantsListItem
                            key={participant.id}
                            participant={participant}
                            onClickRemove={props.readOnly ? undefined : onClickRemoveParticipant}
                        />
                    );
                })}
            </div>
        </div>
    );
};
