import { useState } from 'react';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { ParticipantNotesModal } from './ParticipantNotesModal/ParticipantNotesModal';
import './ParticipantNotesWrapper.css';
import { useParticipants } from '../../models/Participant/Hooks';

export const ParticipantNotesWrapper = () => {
    const participant = useParticipants((x) => x.selectedParticipant);
    const [showNotesModal, setShowNotesModal] = useState<boolean>(false);

    /**
     * Show notes modal
     */
    const onShowNotesModal = () => {
        setShowNotesModal(!showNotesModal);
    };

    return (
        <div className={'participant-notes-wrapper'}>
            {participant && (
                <Button
                    type={'primary'}
                    className={'participant-notes-wrapper-button'}
                    size={'large'}
                    buttonStyle={'outline'}
                    firstIcon={<Icon type={'DocumentText'} />}
                    text={'Notizen öffnen'}
                    onClick={onShowNotesModal}
                />
            )}
            {participant && (
                <ParticipantNotesModal show={showNotesModal} onClose={onShowNotesModal} participant={participant} />
            )}
        </div>
    );
};
