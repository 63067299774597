import { FormikErrors } from 'formik';
import React from 'react';
import { useAppDispatch } from '../../hooks';
import { FormikHandleChange, FormikSetFieldValue } from '../../models/Formik/Type';
import { mapParticipantMobility, participantMobility } from '../../models/Participant/Participant';
import { ParticipantMobility, ParticipantWithPresence } from '../../models/Participant/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { ValueCheckbox } from '../ValueCheckbox/ValueCheckbox';
import { Input } from '../Input/Input';
import { List } from '../List/List';
import { useParticipantsApi } from '../../api/useParticipantsApi';
import { participantUpdated } from '../../models/Participant/Slice';
import { useParticipants } from '../../models/Participant/Hooks';

interface ParticipantMobilityCardFormProps {
    errors: FormikErrors<ParticipantWithPresence>;
    handleChange: FormikHandleChange;
    setFieldValue: FormikSetFieldValue;
    values: ParticipantWithPresence;
}

export const ParticipantMobilityCardForm = (props: ParticipantMobilityCardFormProps) => {
    const selectedParticipant = useParticipants((x) => x.selectedParticipant);
    const { apiUpdateParticipant } = useParticipantsApi();
    const dispatch = useAppDispatch();

    /**
     * Update mobility information in case it was clicked in the form
     * @param mobility
     */
    const onChangeMobility = async (mobility: ParticipantMobility) => {
        if (props.values.mobilities) {
            let mobilityValues = [...props.values.mobilities];
            if (
                mobilityValues &&
                mobilityValues.length > 0 &&
                mobilityValues?.some((mobilityValue) => mobilityValue.mobility === mobility)
            ) {
                mobilityValues?.splice(
                    mobilityValues?.findIndex((mobilityValue) => mobilityValue.mobility === mobility),
                    1
                );
            } else if (mobilityValues && mobilityValues.length > 0) {
                mobilityValues.push({ mobility: mobility, notes: '' });
            } else {
                mobilityValues = [{ mobility: mobility, notes: '' }];
            }

            if (selectedParticipant) {
                try {
                    props.setFieldValue('mobilities', mobilityValues);
                    const updatedParticipant = await apiUpdateParticipant(selectedParticipant.id, {
                        ...selectedParticipant,
                        ...{ mobilities: mobilityValues }
                    });
                    dispatch(participantUpdated(updatedParticipant));
                    showSuccessMessage();
                } catch (e) {
                    throwError();
                    console.log(e);
                }
            }
        } else {
            if (selectedParticipant) {
                try {
                    props.setFieldValue('mobilities', [{ mobility: mobility, notes: '' }]);
                    const updatedParticipant = await apiUpdateParticipant(selectedParticipant.id, {
                        ...selectedParticipant,
                        ...{
                            mobilities: [
                                {
                                    mobility: mobility,
                                    notes: ''
                                }
                            ]
                        }
                    });
                    showSuccessMessage();
                    dispatch(participantUpdated(updatedParticipant));
                } catch (e) {
                    throwError();
                    console.log(e);
                }
            }
        }
    };

    /**
     * onBlur call back for update
     */
    const onBlur = async () => {
        if (selectedParticipant) {
            try {
                const updatedParticipant = await apiUpdateParticipant(selectedParticipant.id, {
                    ...selectedParticipant,
                    ...{ otherMobility: props.values.otherMobility }
                });
                showSuccessMessage();
                dispatch(participantUpdated(updatedParticipant));
            } catch (e) {
                throwError();
                console.log(e);
            }
        }
    };

    /**
     * Handler in case the enter key was pressed
     * @param event
     */
    const onKeyEnter = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    return (
        <>
            <List
                options={[
                    {
                        label: 'Fahrzeug',
                        input: (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '2rem'
                                }}
                            >
                                {' '}
                                {participantMobility.map((mobility, index) => {
                                    return (
                                        <ValueCheckbox
                                            key={index}
                                            checked={props.values.mobilities?.some(
                                                (singleMobility) => singleMobility.mobility === mobility
                                            )}
                                            onChange={onChangeMobility}
                                            value={mobility}
                                            label={mapParticipantMobility(mobility)}
                                        />
                                    );
                                })}
                            </div>
                        )
                    }
                ]}
            />
            <Input
                placeholder={'Notizen'}
                onKeyDown={onKeyEnter}
                onBlur={onBlur}
                value={props.values.otherMobility}
                onChange={props.handleChange}
                name={'otherMobility'}
            />
        </>
    );
};
