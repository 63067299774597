import React, { useState } from 'react';
import useSWR from 'swr';
import { NeedForAction, NeedForActionSuggestion } from '../../models/NeedForAction/Type';
import { ObjectiveAgreementWithNeedForActionsAndSupportPlan } from '../../models/ObjectiveAgreement/Type';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { NeedForActionCard } from '../NeedForAction/NeedForActionCard';
import { ObjectList } from '../ObjectList/ObjectList';
import { ObjectListItem } from '../ObjectListItem/ObjectListItem';
import { StandardView } from '../StandardView/StandardView';
import { UpdateNeedForAction } from '../UpdateNeedForAction/UpdateNeedForAction';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useMeasures } from '../../models/Measure/Hooks';

interface ParticipantDetailInformationViewNeedForActionsProps {
    participant: ParticipantWithPresence | undefined;
}

export const ParticipantDetailInformationViewNeedForActions = (
    props: ParticipantDetailInformationViewNeedForActionsProps
) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const currentMeasure = useMeasures((x) => x.selectedMeasure);
    const [filter, setFilter] = useState<string>('high');
    const [selectedNeedForAction, setSelectedNeedForAction] = useState<NeedForAction>();
    const { data: needForActions } = useSWR<NeedForAction[]>(
        [
            backendUrl,
            `participants/${props.participant?.id}/need_for_actions`,
            'GET',
            { measureId: currentMeasure?.id },
            authInfo,
            apiVersion
        ],
        { refreshInterval: 10000 }
    );
    const { data: objectiveAgreements } = useSWR<ObjectiveAgreementWithNeedForActionsAndSupportPlan[]>([
        backendUrl,
        `participants/${props.participant?.id}/objective_agreements`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);
    const { data: suggestions } = useSWR<NeedForActionSuggestion[]>([
        backendUrl,
        'need_for_actions/suggestions',
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * Set need for action in order to update it
     * @param needForAction
     */
    const onChangeNeedForAction = (needForAction?: NeedForAction) => {
        if (selectedNeedForAction) {
            setSelectedNeedForAction(undefined);
        } else {
            setSelectedNeedForAction(needForAction);
        }
    };

    /**
     * Render need for actions based on filter
     */
    const renderNeedForActions = () => {
        switch (filter) {
            case 'low':
            case 'medium':
            case 'high':
                return needForActions
                    ?.filter((action) => action.priority === filter && !action.closed)
                    .map((action) => (
                        <NeedForActionCard
                            key={action.id}
                            objectiveAgreement={objectiveAgreements?.filter(
                                (objectiveAgreement) => objectiveAgreement.needForAction.id === action.id
                            )}
                            needForAction={action}
                            onClick={onChangeNeedForAction}
                        />
                    ));
            case 'date':
                return needForActions
                    ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                    .filter((action) => action.priority !== 'information' && !action.closed)
                    .map((action: NeedForAction) => (
                        <NeedForActionCard
                            key={action.id}
                            objectiveAgreement={objectiveAgreements?.filter(
                                (objectiveAgreement) => objectiveAgreement.needForActionId === action.id
                            )}
                            needForAction={action}
                            onClick={onChangeNeedForAction}
                        />
                    ));
            case 'finished':
                return needForActions
                    ?.filter((action) => action.closed)
                    .map((action) => (
                        <NeedForActionCard
                            key={action.id}
                            objectiveAgreement={objectiveAgreements?.filter(
                                (objectiveAgreement) => objectiveAgreement.needForAction.id === action.id
                            )}
                            needForAction={action}
                            onClick={onChangeNeedForAction}
                        />
                    ));
            case 'other':
                return needForActions
                    ?.filter((action) => action.category === 'Sonstiges' && !action.closed)
                    .map((action) => (
                        <NeedForActionCard
                            key={action.id}
                            objectiveAgreement={objectiveAgreements?.filter(
                                (objectiveAgreement) => objectiveAgreement.needForAction.id === action.id
                            )}
                            needForAction={action}
                            onClick={onChangeNeedForAction}
                        />
                    ));
            default:
                return needForActions?.map((action) => (
                    <NeedForActionCard
                        key={action.id}
                        objectiveAgreement={objectiveAgreements?.filter(
                            (objectiveAgreement) => objectiveAgreement.needForActionId === action.id
                        )}
                        needForAction={action}
                        onClick={onChangeNeedForAction}
                    />
                ));
        }
    };

    const needForActionFilter: { value: string; id: string }[] = [
        { value: 'hohe Priorität', id: 'high' },
        { value: 'mittlere Priorität', id: 'medium' },
        { value: 'niedrige Priorität', id: 'low' },
        { value: 'erledigte Handlungsbedarfe', id: 'finished' },
        { value: 'Chronologie der Handlungsbedarfe', id: 'date' },
        { value: 'sonstige', id: 'other' }
    ];

    const onClickMenu = (id: number | string) => {
        if (typeof id === 'string') {
            setFilter(id);
        }
    };

    return (
        <StandardView>
            {selectedNeedForAction ? (
                <UpdateNeedForAction
                    dontShowGoBack
                    correspondingSuggestion={suggestions
                        ?.find((suggestion) =>
                            suggestion.needForActionTypes.find(
                                (type) => type.needForActionType === selectedNeedForAction?.needForActionType
                            )
                        )
                        ?.needForActionTypes.find(
                            (type) => type.needForActionType === selectedNeedForAction?.needForActionType
                        )}
                    onClose={onChangeNeedForAction}
                    needForAction={selectedNeedForAction}
                    onGoBack={onChangeNeedForAction}
                    multipleAnswers={selectedNeedForAction.needForActionType.includes('Mehrfachnennung möglich')}
                />
            ) : (
                <>
                    <StandardView.Left>
                        <ObjectList>
                            <ObjectList.Head>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '24px'
                                    }}
                                >
                                    <div className="p2-medium">Handlungsbedarfe</div>
                                </div>
                            </ObjectList.Head>
                            <ObjectList.Body>
                                {needForActionFilter.map((menuOption: { value: string; id: string }, index) => {
                                    return (
                                        <ObjectListItem
                                            key={index}
                                            value={{ index: menuOption.id, item: menuOption.value }}
                                            selected={filter === menuOption.id}
                                            onClick={onClickMenu}
                                        >
                                            <div key={index}>{menuOption.value}</div>
                                        </ObjectListItem>
                                    );
                                })}
                            </ObjectList.Body>
                        </ObjectList>
                    </StandardView.Left>
                    <StandardView.Right>{renderNeedForActions()}</StandardView.Right>
                </>
            )}
        </StandardView>
    );
};
