import { NeedCommunity } from './Types';
import { Participant } from '../Participant/Types';

/**
 * Returns all participants of all given needCommunities in one list.
 *
 * @param needCommunities
 */
const allParticipants = (needCommunities: NeedCommunity[]): Participant[] => {
    return needCommunities.flatMap((needCommunity) => needCommunity.participants || []);
};

export const NeedCommunityUtils = {
    allParticipants
};
