import React, { ReactElement, Ref, useCallback, useLayoutEffect, useState } from 'react';

export type IconType =
    | 'Archive'
    | 'ArrowDown'
    | 'ArrowDownLeft'
    | 'ArrowDownRight'
    | 'ArrowLeft'
    | 'ArrowRight'
    | 'ArrowUp'
    | 'ArrowUpLeft'
    | 'ArrowUpRight'
    | 'Calendar'
    | 'Check'
    | 'CheckCircle'
    | 'CheckCircleFill'
    | 'ChevronDown'
    | 'ChevronLeft'
    | 'ChevronRight'
    | 'ChevronUp'
    | 'Circle'
    | 'Close'
    | 'CloseCircle'
    | 'CloseSmall'
    | 'Dashboard'
    | 'Delete'
    | 'Document'
    | 'DocumentAdd'
    | 'DocumentDownload'
    | 'DocumentDownloadAlt'
    | 'DocumentRemove'
    | 'DocumentText'
    | 'DocumentUpload'
    | 'DocumentUploadAlt'
    | 'DotsHorizontal'
    | 'DotsVertical'
    | 'Download'
    | 'Enter'
    | 'Folder'
    | 'Information'
    | 'Like'
    | 'Link'
    | 'LinkAlt'
    | 'Menu'
    | 'Minus'
    | 'MinusCircle'
    | 'Plus'
    | 'PlusCircle'
    | 'Search'
    | 'Settings'
    | 'Upload'
    | 'User'
    | 'UserMinus'
    | 'UserPlus'
    | 'UserRemove'
    | 'Users'
    | 'WarningCircle';

interface IconProps {
    // css class for styling
    className?: string;
    // color
    fill?: string;
    // currently available icons;
    type?: IconType;
    // onclick event
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    // any random icon
    iconFile?: ReactElement;

    ref?: Ref<HTMLDivElement>;
}

export const Icon = (props: IconProps) => {
    const [iconComponent, setIconComponent] = useState<ReactElement | null>(null); // icon component that holds svg component

    /**
     * Gets the icon component having the iconName from the source and adds it to the state.
     *
     * @param iconName
     */
    const addComponent = useCallback((iconName: string) => {
        import(`./${iconName}/${iconName}`).then((module) => setIconComponent(module[iconName]));
    }, []);

    /**
     * call addComponent after initial render of component to get svg icon
     */
    useLayoutEffect(() => {
        // import svg based on input
        if (props.type) {
            addComponent(props.type);
        } else if (props.iconFile) {
            setIconComponent(props.iconFile);
        }
    }, [props.type, addComponent, props.iconFile]);

    return (
        <div
            ref={props.ref}
            className={`d-flex ${props.className} ${props.onClick ? 'cursor-pointer' : ''}`}
            style={{ fill: props.fill }}
            onClick={props.onClick}
        >
            {iconComponent}
        </div>
    );
};
