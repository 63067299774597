/**
 * Confirguation object that holds the routing strings for the react router.
 */
export const AppRoutes = {
    welcome: '/welcome',
    login: '/login',
    register: '/register',
    lostPassword: '/lost_password',
    resendConfirmation: '/resend_confirmation',
    admin: {
        projects: '/admin/projects', // Maßnahmen
        qmDocuments: '/admin/qm_documents', // Qm-Dokumente
        users: '/admin/users'
    },
    projectsTab: (id?: number) => (id ? `/projects/${id}/participants/` : '/projects/:id/participants'),
    attendanceTab: (id?: number) => (id ? `/projects/${id}/attendance/` : '/projects/:id/attendance'),
    participantDetails: (id?: number) =>
        id ? `/projects/${id}/participants/details` : '/projects/:id/participants/details',
    participantDetailsNeedForAction: (id?: number) =>
        id
            ? `/projects/${id}/participants/details/need_for_action`
            : '/projects/:id/participants/details/need_for_action',
    participantDetailsSupportPlan: (id?: number) =>
        id ? `/projects/${id}/participants/details/support_plans` : '/projects/:id/participants/details/support_plans',
    participantDetailsInformation: (id?: number) =>
        id ? `/projects/${id}/participants/details/information` : '/projects/:id/participants/details/information',
    participantDetailsContacts: (id?: number) =>
        id ? `/projects/${id}/participants/details/contacts` : '/projects/:id/participants/details/contacts',
    measureManagement: (id?: number) => (id ? `/projects/${id}/management/` : '/projects/:id/management'),
    administration: '/administration', // Verwaltung
    participantDashboard: '/participant_dashboard', // Teilnehmerdashboard
    adminView: '/admin', // Admin
    components: '/components',
    tasks: '/tasks',
    measureCompanies: (id?: number) =>
        id ? `/projects/${id}/management/companies` : '/projects/:id/management/companies',
    measureLetterTemplates: (id?: number) =>
        id ? `/projects/${id}/management/letterTemplates` : '/projects/:id/management/letterTemplates',
    measureStatistics: (id?: number) =>
        id ? `/projects/${id}/management/statistics` : '/projects/:id/management/statistics',
    measureCourses: (id?: number) => (id ? `/projects/${id}/management/courses` : '/projects/:id/management/courses'),
    measureNeedCommunities: (id?: number) => `/projects/${id}/need_communities`
};
