import { NeedCommunity } from '../../models/NeedCommunity/Types';
import { ParticipantArchiveModal } from '../ParcitipantArchiveModal/ParticipantArchiveModal';
import React from 'react';

/**
 * Props for the NeedCommunityCloseModal component.
 */
interface NeedCommunityCloseModalProps {
    // Indicates whether the modal should be currently shown
    show: boolean;

    // Called if the modal was closed.
    onClose: () => void;

    // If this is given, the archive request will be called on the need community instead of the current selected participant
    needCommunity?: NeedCommunity;
}

/**
 * Shows a modal to close the need community given by the props.
 *
 * @param props
 * @constructor
 */
export const NeedCommunityCloseModal = (props: NeedCommunityCloseModalProps) => {
    return props.needCommunity ? (
        <ParticipantArchiveModal needCommunity={props.needCommunity} show={props.show} onClose={props.onClose} />
    ) : null;
};
