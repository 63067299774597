import { useState } from 'react';
import { AppRoutes } from '../../configuration/AppRoutes';
import { useAppDispatch } from '../../hooks';
import { setSelectedMeasure } from '../../models/Measure/Slice';
import { Measure } from '../../models/Measure/Types';
import { Icon } from '../Icon/Icon';
import { SidebarItem } from '../SidebarItem/SidebarItem';
import { SidebarItemNav } from '../SidebarItemNav/SidebarItemNav';
import './SidebarBody.css';
import { setSelectedParticipant } from '../../models/Participant/Slice';
import { useSession } from '../../hooks/useSession';

interface SideBarBodyProps {
    activeMeasures?: Measure[];
    onClickClose: () => void;
    menuItemSelected: string;
    onChangeMenuItem: (arg: string) => void;
}

export const SideBarBody = (props: SideBarBodyProps) => {
    const [childItemSelected, setChildItemSelected] = useState<string>('');
    const dispatch = useAppDispatch();
    const { isQmManager, isAdministrator } = useSession();

    const { menuItemSelected, onChangeMenuItem } = props;

    const onItemClicked = (item: string) => {
        dispatch(setSelectedParticipant(undefined));
        setChildItemSelected(item);
        props.onClickClose();
    };

    /**
     * Set the selected measure to the redux state
     * @param measureId
     */
    const onSwitchMeasure = (measureId: string | number) => {
        onChangeMenuItem(measureId.toString());
        dispatch(setSelectedMeasure(props.activeMeasures?.find((measure) => measure.id === measureId)));
    };

    return (
        <div className="side-bar-body">
            {isAdministrator() && (
                <SidebarItem
                    type={'secondary'}
                    isSelected={menuItemSelected === 'administration'}
                    iconFront={<Icon type={'Settings'} />}
                    text={'Administration'}
                    iconBack={
                        menuItemSelected === 'administration' ? (
                            <Icon type={'ChevronUp'} />
                        ) : (
                            <Icon type={'ChevronDown'} />
                        )
                    }
                    onClick={() => onChangeMenuItem('administration')}
                >
                    <SidebarItemNav
                        title={'Maßnahmeverwaltung'}
                        link={AppRoutes.admin.projects}
                        spacer
                        isSelected={childItemSelected === 'adminMeasure'}
                        onClick={() => onItemClicked('adminMeasure')}
                    />
                    <SidebarItemNav
                        title={'Mitarbeiter'}
                        spacer
                        isSelected={childItemSelected === 'adminEmployee'}
                        onClick={() => onItemClicked('adminEmployee')}
                        link={AppRoutes.admin.users}
                    />
                    <SidebarItemNav
                        title={'QM-Dokumente'}
                        spacer
                        isSelected={childItemSelected === 'adminQmDocuments'}
                        onClick={() => onItemClicked('adminQmDocuments')}
                        link={AppRoutes.admin.qmDocuments}
                    />
                </SidebarItem>
            )}
            {isQmManager() && (
                <SidebarItem
                    type={'secondary'}
                    isSelected={menuItemSelected === 'administration'}
                    iconFront={<Icon type={'Settings'} />}
                    text={'Administration'}
                    iconBack={
                        menuItemSelected === 'administration' ? (
                            <Icon type={'ChevronUp'} />
                        ) : (
                            <Icon type={'ChevronDown'} />
                        )
                    }
                    onClick={() => onChangeMenuItem('administration')}
                >
                    <SidebarItemNav
                        title={'QM-Dokumente'}
                        spacer
                        isSelected={childItemSelected === 'adminQmDocuments'}
                        onClick={() => onItemClicked('adminQmDocuments')}
                        link={AppRoutes.admin.qmDocuments}
                    />
                </SidebarItem>
            )}
            {props.activeMeasures?.map((measure) => {
                return (
                    <SidebarItem
                        key={measure.id}
                        type={'secondary'}
                        iconFront={<Icon type={'Folder'} />}
                        text={measure.name}
                        isOldMeasure={measure.children ? measure.children.length > 0 : false}
                        iconBack={
                            menuItemSelected === `${measure.id}` ? (
                                <Icon type={'ChevronUp'} />
                            ) : (
                                <Icon type={'ChevronDown'} />
                            )
                        }
                        isSelected={menuItemSelected === `${measure.id}`}
                        onClick={() => onSwitchMeasure(measure.id)}
                    >
                        <SidebarItemNav
                            link={`${AppRoutes.projectsTab(measure.id)}`}
                            title={'Aktive Teilnehmer'}
                            spacer
                            isSelected={childItemSelected === `activeParticipant-${measure.id}`}
                            onClick={() => onItemClicked(`activeParticipant-${measure.id}`)}
                        />
                        <SidebarItemNav
                            link={`${AppRoutes.attendanceTab(measure.id)}`}
                            title={'Anwesenheit'}
                            spacer
                            isSelected={childItemSelected === `attendance-${measure.id}`}
                            onClick={() => onItemClicked(`attendance-${measure.id}`)}
                        />
                        <SidebarItemNav
                            title={'Maßnahmeverwaltung'}
                            link={`${AppRoutes.measureCompanies(measure.id)}`}
                            spacer
                            isSelected={childItemSelected === `adminMeasure-${measure.id}`}
                            onClick={() => onItemClicked(`adminMeasure-${measure.id}`)}
                        />
                        {measure.hasNeedCommunities ? (
                            <SidebarItemNav
                                title={'Bedarfsgemeinschaften'}
                                link={`${AppRoutes.measureNeedCommunities(measure.id)}`}
                                spacer
                                isSelected={childItemSelected === `need-communities-${measure.id}`}
                                onClick={() => onItemClicked(`need-communities-${measure.id}`)}
                            />
                        ) : null}
                    </SidebarItem>
                );
            })}
        </div>
    );
};
