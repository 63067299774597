import React, { ChangeEvent, useEffect, useState } from 'react';
import useSWR from 'swr';
import { formatDate } from '../../../models/Date/Date';
import { ParticipantNote, ParticipantWithPresence } from '../../../models/Participant/Types';
import { Button } from '../../Button/Button';
import { Icon } from '../../Icon/Icon';
import { Input } from '../../Input/Input';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { ObjectList } from '../../ObjectList/ObjectList';
import { ObjectListItem } from '../../ObjectListItem/ObjectListItem';
import { StandardView } from '../../StandardView/StandardView';
import { ParticipantNotesDetails } from '../ParticipantNotesDetails/ParticipantNotesDetails';
import './ParticipantNotes.css';
import { Checkbox } from '../../Checkbox/Checkbox';
import { useNotesApi } from '../../../api/useNotesApi';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';

interface ParticipantNotesProps {
    onClose: () => void;
    participant: ParticipantWithPresence;
    show: boolean;
}

export const ParticipantNotes = (props: ParticipantNotesProps) => {
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showHighlighted, setShowHighlighted] = useState<boolean>(false);
    const [selectedParticipantNote, setSelectedParticipantNote] = useState<ParticipantNote>();
    const [filteredNotes, setFilteredNotes] = useState<ParticipantNote[]>();
    const [backgroundClosed, setBackgroundClosed] = useState<boolean>(true);
    const { apiCreateNewParticipantNote } = useNotesApi();
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();

    const { data: allNotes, mutate: reloadNotes } = useSWR<ParticipantNote[]>([
        backendUrl,
        `participants/${props.participant.id}/participant_notes`,
        'GET',
        null,
        authInfo,
        apiVersion
    ]);

    /**
     * Show search bar
     */
    const onClickOpenSearch = () => {
        setShowSearch(!showSearch);
    };

    /**
     * Create new note
     */
    const onCreateNewNote = async () => {
        const newNote = await apiCreateNewParticipantNote({
            text: 'Neue Notiz',
            category: '',
            heading: 'Neue Notiz',
            date: new Date(),
            highlight: false,
            participantId: props.participant.id
        });
        await reloadNotes();
        setSelectedParticipantNote(newNote);
    };

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setFilteredNotes(
            allNotes?.filter((note) => note.heading.toLowerCase().includes(event.target.value.toLowerCase() as string))
        );
    };

    /**
     * Write the selected participant into the state
     * @param _index
     * @param participantNote
     */
    const onSelectParticipantNote = (_index: number | string, participantNote: ParticipantNote) => {
        setSelectedParticipantNote(participantNote);
    };

    /**
     * Filter to show the highlighted notes
     */
    const onChangeShowHighlighted = () => {
        setShowHighlighted(!showHighlighted);
    };

    /**
     * If there is no note selected, set the first item of allNotes as the selected one
     */
    useEffect(() => {
        if (!selectedParticipantNote && allNotes) {
            setSelectedParticipantNote(allNotes[0]);
        }
    }, [allNotes, selectedParticipantNote]);

    /**
     * If the selected participant note is not anymore part of the requested notes, set a new seletec participant note
     */
    useEffect(() => {
        if (allNotes && !allNotes.some((note) => note.id === selectedParticipantNote?.id)) {
            setSelectedParticipantNote(allNotes[0]);
        }
    }, [allNotes, selectedParticipantNote?.id]);

    /**
     * When notes are fetched set them into the filter state
     */
    useEffect(() => {
        setFilteredNotes(allNotes);
    }, [allNotes]);

    /**
     * This is a nasty hack to make the background to wait for the finish of the inner container animation.
     * If we would hide the background earilier, the animation will not be shown.
     */
    useEffect(() => {
        if (props.show) {
            setBackgroundClosed(false);
        } else {
            setTimeout(() => {
                setBackgroundClosed(true);
            }, 1000);
        }
    }, [props.show]);

    return (
        <div className={`participant-notes-outer-container ${backgroundClosed ? '' : 'open'}`}>
            <div className={`participant-notes-container ${props.show ? 'open' : ''}`}>
                <div className={'qm-document-modal body'}>
                    <StandardView>
                        <StandardView.Left>
                            <ObjectList>
                                <ObjectList.Head>
                                    <div className={'user-list-head-container'} style={{ marginBottom: '24px' }}>
                                        <div className={'user-list-head'}>
                                            <div className="p2-medium">Notizen</div>
                                            <div style={{ display: 'flex', gap: '16px' }}>
                                                <Button
                                                    type={'secondary'}
                                                    buttonStyle={'link'}
                                                    size={'small'}
                                                    firstIcon={<Icon type={'Search'} />}
                                                    onClick={onClickOpenSearch}
                                                />
                                                <Button
                                                    type={'primary'}
                                                    size={'small'}
                                                    buttonStyle={'filled'}
                                                    firstIcon={<Icon type={'Plus'} />}
                                                    onClick={onCreateNewNote}
                                                />
                                            </div>
                                        </div>
                                        {showSearch && (
                                            <>
                                                <Input
                                                    icon={<Icon type={'Search'} />}
                                                    placeholder={'Notizen suchen'}
                                                    onChange={onChangeSearch}
                                                />
                                                <Checkbox
                                                    value={showHighlighted}
                                                    onChange={onChangeShowHighlighted}
                                                    label={'Nur wichtige Notizen anzeigen'}
                                                />
                                            </>
                                        )}
                                    </div>
                                </ObjectList.Head>
                                <ObjectList.Body>
                                    {filteredNotes ? (
                                        filteredNotes
                                            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                                            .filter((note) =>
                                                showHighlighted ? note.highlight === showHighlighted : note
                                            )
                                            .map((participantNote, index) => {
                                                return (
                                                    <ObjectListItem
                                                        key={index}
                                                        value={{ index: index, item: participantNote }}
                                                        selected={selectedParticipantNote?.id === participantNote.id}
                                                        onClick={onSelectParticipantNote}
                                                    >
                                                        <div>{participantNote.heading}</div>
                                                        <div className={'participant-notes-info'}>
                                                            <div>
                                                                {participantNote.date &&
                                                                    formatDate(new Date(participantNote.date))}
                                                            </div>
                                                            <div>{participantNote.highlight && 'wichtig'}</div>
                                                        </div>
                                                    </ObjectListItem>
                                                );
                                            })
                                    ) : (
                                        <LoadingSpinner />
                                    )}
                                </ObjectList.Body>
                            </ObjectList>
                        </StandardView.Left>
                        <StandardView.Right>
                            {selectedParticipantNote && (
                                <ParticipantNotesDetails
                                    reloadNotes={reloadNotes}
                                    participantNote={selectedParticipantNote}
                                />
                            )}
                        </StandardView.Right>
                    </StandardView>
                </div>
                <div className={'footer'}>
                    <Button
                        type={'primary'}
                        size={'medium'}
                        buttonStyle={'filled'}
                        text={'Schließen'}
                        onClick={props.onClose}
                    />
                </div>
            </div>
        </div>
    );
};
