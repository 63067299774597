import React, { FocusEvent, ReactElement } from 'react';
import { EditableItem } from '../EditableItem/EditableItem';
import './ListElement.css';
import { FormikHandleChange } from '../../models/Formik/Type';

export interface ListElementProps {
    label?: string;
    value?: string | number | Date | boolean | undefined;
    onChange?: FormikHandleChange;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    name?: string;
    readOnly?: boolean;
    valueClassName?: string;
    input?: ReactElement;
    error?: string;
    onClick?: () => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const ListElement = (props: ListElementProps) => {
    return (
        <>
            <div className="list-element-label p4-medium">{props.label}:</div>
            {!props.input ? (
                <EditableItem
                    onKeyDown={props.onKeyDown}
                    onClick={props.onClick}
                    error={props.error}
                    className={props.valueClassName}
                    readOnly={props.readOnly}
                    name={props.name}
                    onBlur={props.onBlur}
                    value={props.value}
                    onChange={props.onChange}
                />
            ) : (
                props.input
            )}
        </>
    );
};
