import './ObjectListBody.css';
import { ReactNode } from 'react';

interface ObjectListBodyProps {
    children: ReactNode;
}

export const ObjectListBody = (props: ObjectListBodyProps) => {
    return <div className="object-list-body">{props.children}</div>;
};
