import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { PresenceType } from '../../models/Presence/Types';
import { showSuccessMessage, throwError } from '../../models/Toasts/Toasts';
import { Attendance } from '../Attendance/Attendance';
import { Icon } from '../Icon/Icon';
import { usePresencesApi } from '../../api/usePresencesApi';
import { useMeasures } from '../../models/Measure/Hooks';
import { presenceCreated, presenceUpdated } from '../../models/Participant/Slice';

interface PresenceCalendarChangeModalProps {
    // information that should be display
    attendanceInformation:
        | {
              event: React.MouseEvent<HTMLDivElement>;
              id: number | undefined;
              date: Date;
              participant: ParticipantWithPresence;
          }
        | undefined;
    // boolean to indicate if the component should be shown
    showOptions: boolean;
    // callback to open/close the component
    setShowOptions: (arg: boolean) => void;
}

export const PresenceCalendarChangeModal = (props: PresenceCalendarChangeModalProps) => {
    const attendanceCategories: (PresenceType | undefined)[] = [
        undefined,
        'present',
        'delayed',
        'absent_unexplained',
        'absent_explained',
        'instruction_free_time',
        'sickness',
        'other',
        'interview',
        'practicum',
        'measure_end'
    ];
    const measureId = useMeasures((x) => x.selectedMeasure?.id);
    const modalRef = useRef<HTMLDivElement>(null);
    const { attendanceInformation, showOptions, setShowOptions } = props;
    const [modalIsOutside, setModalIsOutside] = useState<boolean>();
    const { apiUpdatePresence, apiCreatePresence } = usePresencesApi();
    const dispatch = useAppDispatch();

    /**
     * Change attendance information of user
     * @param attendance
     */
    const onChangeAttendance = async (attendance: PresenceType | undefined) => {
        if (attendanceInformation && measureId && attendanceInformation.id) {
            try {
                const updatedPresence = await apiUpdatePresence(attendanceInformation.id, {
                    category: attendance ? attendance : null,
                    presentAt: attendanceInformation.date,
                    participantId: attendanceInformation.participant.id,
                    measureId: measureId
                });

                dispatch(presenceUpdated(updatedPresence));

                showSuccessMessage();
            } catch (e) {
                throwError('Beim Update ist ein Fehler passiert.');
                console.log(e);
            }
        } else if (measureId && attendanceInformation) {
            try {
                const createdPresence = await apiCreatePresence({
                    category: attendance ? attendance : null,
                    presentAt: attendanceInformation.date,
                    participantId: attendanceInformation.participant.id,
                    measureId: measureId
                });

                dispatch(presenceCreated(createdPresence));

                showSuccessMessage();
            } catch (e) {
                throwError('Fehler beim Erstellen.');
                console.log(e);
            }
        }

        setShowOptions(false);
    };

    /**
     * Callback to close the modal and reset the information if the modal is outside the viewport
     */
    const closeModal = () => {
        setModalIsOutside(false);
        setShowOptions(false);
    };

    /**
     * Update position of modal in case it overflows the viewport
     */
    useLayoutEffect(() => {
        if (modalRef && modalRef.current) {
            if (
                modalRef.current.getBoundingClientRect().right >
                (window.innerWidth || document.documentElement.clientWidth)
            ) {
                setModalIsOutside(true);
            }
        }
    }, [showOptions]);

    return showOptions ? (
        <>
            {' '}
            <div
                className="arrow-up"
                style={{
                    top: attendanceInformation && attendanceInformation?.event.clientY - 7,
                    left: attendanceInformation?.event.clientX
                }}
            />
            <div
                ref={modalRef}
                className="presence-calendar-attendance-menu"
                style={{
                    top: attendanceInformation?.event.clientY,
                    left: attendanceInformation?.event.clientX,
                    transform: `${modalIsOutside ? 'translateX(-85%)' : 'translateX(-50%)'}`
                }}
            >
                <div className="presence-calendar-attendances">
                    {attendanceCategories.map((attendanceCategory, index) => {
                        return attendanceCategory === 'measure_end' &&
                            attendanceInformation?.participant.measuresParticipant.inactiveDate ? (
                            <Fragment key={index}></Fragment>
                        ) : (
                            <div key={index}>
                                <Attendance onClick={onChangeAttendance} category={attendanceCategory} />
                            </div>
                        );
                    })}
                    <Icon className={'presence-calendar-attendance-menu-close'} type={'Close'} onClick={closeModal} />
                </div>
            </div>
        </>
    ) : null;
};
