import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../configuration/AppRoutes';
import { Label } from '../Label/Label';
import { useMeasures } from '../../models/Measure/Hooks';

interface AppNavigationSubMenuProps {
    items: string[] | undefined;
}

export const AppNavigationSubMenu = (props: AppNavigationSubMenuProps) => {
    const [clickState, setClickState] = useState<string>(props.items ? props.items[0] : '');
    const measureId = useMeasures((x) => x.selectedMeasure?.id);

    const onClickItem = (arg: string) => {
        setClickState(arg);
    };

    /**
     * Return route of clicked item
     * @param item
     */
    const getRoute = (item: string) => {
        switch (item) {
            case 'Betriebe':
                return AppRoutes.measureCompanies(measureId);
            case 'Briefvorlagen':
                return AppRoutes.measureLetterTemplates(measureId);
            case 'Statistik':
                return AppRoutes.measureStatistics(measureId);
            case 'Qualifizierungsangebote':
                return AppRoutes.measureCourses(measureId);
            case 'Förderpläne':
                return AppRoutes.participantDetailsSupportPlan(measureId);
            case 'Handlungsbedarf':
                return AppRoutes.participantDetailsNeedForAction(measureId);
            case 'Informationen':
                return AppRoutes.participantDetailsInformation(measureId);
            case 'Ansprechpartner':
                return AppRoutes.participantDetailsContacts(measureId);
            default:
                return AppRoutes.participantDetailsSupportPlan(measureId);
        }
    };

    return (
        <>
            {' '}
            {props.items?.map((item, index) => {
                return (
                    <Link key={index} to={getRoute(item)}>
                        <Label
                            size={2}
                            onClick={() => onClickItem(item)}
                            className={`sub-menu-items-option ${clickState === item && 'sub-menu-items-option-active'}`}
                        >
                            {item}
                        </Label>
                    </Link>
                );
            })}
        </>
    );
};
