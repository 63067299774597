import useSWR from 'swr';
import { mapCourseCategories } from '../../models/Course/Course';
import { Course } from '../../models/Course/Type';
import { formatDate } from '../../models/Date/Date';
import { ParticipantWithPresence } from '../../models/Participant/Types';
import { Card } from '../Card/Card';
import { Label } from '../Label/Label';
import { useEzOnRails } from '@d4us1/ez-on-rails-react';
import { useMeasures } from '../../models/Measure/Hooks';

interface ParticipantCoursesProps {
    participant: ParticipantWithPresence;
}

export const ParticipantCourses = (props: ParticipantCoursesProps) => {
    const { backendUrl, authInfo, apiVersion } = useEzOnRails();
    const currentMeasure = useMeasures((x) => x.selectedMeasure);
    const { data: courses } = useSWR<Course[]>([
        backendUrl,
        `participants/${props.participant.id}/courses`,
        'GET',
        { measureId: currentMeasure?.id },
        authInfo,
        apiVersion
    ]);

    return (
        <Card key={props.participant.id}>
            <Label className="user-details-general-title" size={2}>
                QUALIFIZIERUNGSANGEBOTE
            </Label>
            <div className="company-internships">
                <div className="company-internships-head">
                    <Label size={2} color={'#A1A1AA'}>
                        Kategorie
                    </Label>
                    <Label size={2} color={'#A1A1AA'}>
                        Inhalt
                    </Label>
                    <Label size={2} color={'#A1A1AA'}>
                        Datum
                    </Label>
                </div>
                <div className="company-internships-body">
                    {courses?.map((course) => {
                        return (
                            <div key={course.id} className="company-internships-body-item">
                                <div className="company-internships-body-item-element">
                                    {mapCourseCategories(course.category)}
                                </div>
                                <div className="company-internships-body-item-element">{course.description}</div>
                                <div className="company-internships-body-item-element">
                                    {formatDate(new Date(course.date))}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div></div>
            </div>
        </Card>
    );
};
