import { useNeedCommunities } from '../../models/NeedCommunity/Hooks';
import { Formik } from 'formik';
import { NeedCommunityProperties } from '../../models/NeedCommunity/Types';
import { useNeedCommunitiesApi } from '../../api/useNeedCommunitiesApi';
import { useAppDispatch } from '../../hooks';
import {
    needCommunityDeleted,
    needCommunityParticipantAdded,
    needCommunityParticipantRemoved,
    needCommunityUpdated
} from '../../models/NeedCommunity/Slice';
import { toast } from 'react-toastify';
import { List } from '../List/List';
import React, { useMemo, useState } from 'react';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { SelectableParticipantsList } from '../SelectableParticipantsList/SelectableParticipantsList';
import { Participant } from '../../models/Participant/Types';
import { useParticipants } from '../../models/Participant/Hooks';
import { NeedCommunityUtils } from '../../models/NeedCommunity/Utils';
import { NeedCommunityCloseModal } from '../NeedCommunityCloseModal/NeedCommunityCloseModal';

/**
 * Shows a form for the current selected need community.
 *
 * @constructor
 */
export const NeedCommunityForm = () => {
    const selectedNeedCommunity = useNeedCommunities((x) => x.selectedNeedCommunity);
    const needCommunities = useNeedCommunities((x) => x.needCommunities);
    const activeParticipants = useParticipants((x) => x.activeParticipants);
    const {
        apiUpdateNeedCommunity,
        apiDeleteNeedCommunity,
        apiAddParticipantToNeedCommunity,
        apiRemoveParticipantFromNeedCommunity
    } = useNeedCommunitiesApi();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCloseModal, setShowCloseModal] = useState(false);
    const dispatch = useAppDispatch();

    /**
     * Called if the user submits the form.
     * Subits the changes to the backend and updates the changes in the state.
     *
     * @param properties
     */
    const onSubmit = async (properties: Partial<NeedCommunityProperties>) => {
        if (!selectedNeedCommunity) return;

        const updatedNeedCommunity = await apiUpdateNeedCommunity(selectedNeedCommunity, properties);

        toast.success('Bedarfsgemeinschaft aktualisiert.', {
            position: 'bottom-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });

        dispatch(needCommunityUpdated(updatedNeedCommunity));
    };

    /**
     * When pressed enter, trigger the blur event
     *
     * @param event
     */
    const onKeyEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    /**
     * Called if the user clicks the button to delete the need comminity.
     * Shows the confirmation modal.
     */
    const onClickDeleteNeedCommunity = () => {
        setShowDeleteModal(true);
    };

    /**
     * Called if the user confirms the deletion of the need community.
     * Deletes the need community in the api and updates the state.
     */
    const onConfirmDeleteNeedCommunity = async () => {
        if (!selectedNeedCommunity) return;

        setShowDeleteModal(false);

        await apiDeleteNeedCommunity(selectedNeedCommunity);

        toast.success('Bedarfsgemeinschaft gelöscht.', {
            position: 'bottom-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });

        dispatch(needCommunityDeleted(selectedNeedCommunity));
    };

    /**
     * Called if the user dismisses the deletion of the need community.
     * Hides the confirmation modal.
     */
    const onDismissDeleteNeedCommunity = () => {
        setShowDeleteModal(false);
    };

    /**
     * Called if the user clicks the button to close the need community.
     * Opens the modal to get all relevant data to close the need community.
     */
    const onClickCloseNeedCommunity = () => {
        setShowCloseModal(true);
    };

    /**
     * Called if the user closes the modal to close thd need community.
     * Hides the modal by updating the state.
     */
    const onCloseCloseModal = () => {
        setShowCloseModal(false);
    };

    /**
     * Filters all participants of the measure that can be added to the need community.
     * Participants being not yet in a need community can be added.
     */
    const selectableParticipants = useMemo(() => {
        if (!needCommunities || !activeParticipants) {
            return [];
        }

        const participantIdsInNeedCommunities = NeedCommunityUtils.allParticipants(needCommunities).map(
            (participant) => participant.id
        );

        return activeParticipants.filter((participant) => {
            return !participantIdsInNeedCommunities.includes(participant.id);
        });
    }, [needCommunities, activeParticipants]);

    /**
     * Called if the user added the participant to the need community.
     * Adds the participant in the backend and updates the state.
     *
     * @param participant
     */
    const onAddParticipant = async (participant: Participant) => {
        if (!selectedNeedCommunity) return;

        await apiAddParticipantToNeedCommunity(selectedNeedCommunity, participant);

        dispatch(needCommunityParticipantAdded({ needCommunity: selectedNeedCommunity, participant }));
    };

    /**
     * Called if the user remoed the participant from the need community.
     * Removes the participant in the backend and updates the state.
     *
     * @param participant
     */
    const onRemoveParticipant = async (participant: Participant) => {
        if (!selectedNeedCommunity) return;

        await apiRemoveParticipantFromNeedCommunity(selectedNeedCommunity, participant);

        dispatch(needCommunityParticipantRemoved({ needCommunity: selectedNeedCommunity, participant }));
    };

    return selectedNeedCommunity ? (
        <div className="measure-information-container">
            <div className="measure-information-container-head">
                <h5>{selectedNeedCommunity.name}</h5>

                <div className={'d-flex flex-row gap-1'}>
                    {!selectedNeedCommunity.closed && (
                        <Button
                            type={'secondary'}
                            size={'medium'}
                            buttonStyle={'outline'}
                            firstIcon={<Icon type={'Close'} />}
                            text={'Schließen'}
                            onClick={onClickCloseNeedCommunity}
                        />
                    )}
                    <Button
                        type={'danger'}
                        size={'medium'}
                        buttonStyle={'outline'}
                        firstIcon={<Icon type={'Delete'} />}
                        text={'Löschen'}
                        onClick={onClickDeleteNeedCommunity}
                    />
                </div>
            </div>
            <Formik
                initialValues={selectedNeedCommunity}
                validationSchema={null}
                enableReinitialize
                // We submit every change onblur
                onSubmit={() => {}}
            >
                {({ values, handleChange, errors }) => (
                    <div>
                        <List
                            key={selectedNeedCommunity.id}
                            readonly={selectedNeedCommunity?.closed}
                            options={[
                                {
                                    label: 'Name',
                                    value: values.name,
                                    name: 'name',
                                    onChange: handleChange,
                                    onBlur: () => onSubmit({ name: values.name }),
                                    error: errors.name,
                                    onKeyDown: onKeyEnter
                                },
                                {
                                    label: 'BG-Nummer',
                                    value: values.identifier,
                                    name: 'identifier',
                                    onChange: handleChange,
                                    onBlur: () => onSubmit({ identifier: values.identifier }),
                                    error: errors.identifier,
                                    onKeyDown: onKeyEnter
                                },
                                {
                                    label: 'Straße und Hausnummer',
                                    name: 'streetAndNumber',
                                    value: values.streetAndNumber,
                                    onChange: handleChange,
                                    onBlur: () => onSubmit({ streetAndNumber: values.streetAndNumber }),
                                    error: errors.streetAndNumber,
                                    onKeyDown: onKeyEnter
                                },
                                {
                                    label: 'Postleitzahl',
                                    name: 'zipCode',
                                    value: values.zipCode,
                                    onChange: handleChange,
                                    onBlur: () => onSubmit({ zipCode: values.zipCode }),
                                    error: errors.zipCode,
                                    onKeyDown: onKeyEnter
                                },
                                {
                                    label: 'Ort',
                                    name: 'city',
                                    value: values.city,
                                    onChange: handleChange,
                                    onBlur: () => onSubmit({ city: values.city }),
                                    error: errors.city,
                                    onKeyDown: onKeyEnter
                                }
                            ]}
                        />
                    </div>
                )}
            </Formik>

            <SelectableParticipantsList
                participants={selectedNeedCommunity.participants || []}
                selectableParticipants={selectableParticipants}
                onAddParticipant={onAddParticipant}
                onRemoveParticipant={onRemoveParticipant}
                readOnly={selectedNeedCommunity.closed}
            />

            <NeedCommunityCloseModal
                show={showCloseModal}
                onClose={onCloseCloseModal}
                needCommunity={selectedNeedCommunity}
            />

            {showDeleteModal && (
                <Modal
                    show={showDeleteModal}
                    header={'Bedarfsgemeinschaft löschen'}
                    buttons={{
                        primary: { text: 'Ja, Bedarfsgemeinschaft löschen', onClick: onConfirmDeleteNeedCommunity },
                        secondary: { text: 'Abbrechen', onClick: onDismissDeleteNeedCommunity },
                        swapped: true
                    }}
                >
                    <div>
                        Sie sind dabei, die Bedarfsgemeinschaft endgültig zu löschen. Eine gelöschte
                        Bedargfsgemeinschaft kann nicht wieder hergestellt werden.
                    </div>
                    <div>Sind Sie sicher, dass Sie die Bedarfsgemeinschaft löschen möchten?</div>
                </Modal>
            )}
        </div>
    ) : null;
};
